import { nanoid } from 'nanoid';
import { __, } from '../index.js';
export const commissioningReferencePattern = /^.*(\w+)-(SD)-(ASP|SWA)-(\d{9,10})$/;
export const getFullName = (customer) => ((customer === null || customer === void 0 ? void 0 : customer.customerFirstName) && (customer === null || customer === void 0 ? void 0 : customer.customerFamilyName)) ||
    ((customer === null || customer === void 0 ? void 0 : customer.firstName) && (customer === null || customer === void 0 ? void 0 : customer.lastName))
    ? `${__((customer === null || customer === void 0 ? void 0 : customer.title) || '')} ${(customer === null || customer === void 0 ? void 0 : customer.customerFirstName) || (customer === null || customer === void 0 ? void 0 : customer.firstName) || ''} ${(customer === null || customer === void 0 ? void 0 : customer.customerFamilyName) || (customer === null || customer === void 0 ? void 0 : customer.familyName) || (customer === null || customer === void 0 ? void 0 : customer.lastName) || ''}`
    : '-';
export function getCaseComplexity(data) {
    const dataCaseRecord = data;
    return data && dataCaseRecord.complexity;
}
export const newToken = () => nanoid(10);
export const generateCommissioningReference = () => `${nanoid(10)}-${nanoid(5)}-${nanoid(2)}`;
export const disguiseCoord = (coord, accuracy = 1000) => Math.round((coord || 0) * accuracy) / accuracy;
export const disguiseLocationAndAddress = ({ latitude, longitude }, accuracy) => ({
    latitude: latitude && disguiseCoord(latitude, accuracy),
    longitude: longitude && disguiseCoord(longitude, accuracy),
});
export const getDefaultDisguiseProps = () => ({
    disguise: `**${__('DISGUISED')}**`,
    disguiseAddress: ' ',
});
function isDisguisableCustomer(obj) {
    return (!!(obj === null || obj === void 0 ? void 0 : obj.customerEmail) ||
        !!(obj === null || obj === void 0 ? void 0 : obj.customerFamilyName) ||
        !!(obj === null || obj === void 0 ? void 0 : obj.customerFirstName) ||
        !!(obj === null || obj === void 0 ? void 0 : obj.customerPhone) ||
        !!(obj === null || obj === void 0 ? void 0 : obj.productNumber) ||
        !!(obj === null || obj === void 0 ? void 0 : obj.commissionExtraDamage) ||
        !!(obj === null || obj === void 0 ? void 0 : obj.commissionReport) ||
        !!(obj === null || obj === void 0 ? void 0 : obj.finalStatusReport));
}
export const disguiseCustomer = (caseRecord, { disguise, disguiseAddress } = getDefaultDisguiseProps()) => (isDisguisableCustomer(caseRecord) && Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign(Object.assign({}, caseRecord), ((caseRecord === null || caseRecord === void 0 ? void 0 : caseRecord.customerEmail) !== undefined
    ? { customerEmail: disguise }
    : {})), ((caseRecord === null || caseRecord === void 0 ? void 0 : caseRecord.customerFamilyName) !== undefined
    ? { customerFamilyName: disguise }
    : {})), ((caseRecord === null || caseRecord === void 0 ? void 0 : caseRecord.customerFirstName) !== undefined
    ? { customerFirstName: disguise }
    : {})), ((caseRecord === null || caseRecord === void 0 ? void 0 : caseRecord.customerPhone) !== undefined
    ? { customerPhone: disguise }
    : {})), ((caseRecord === null || caseRecord === void 0 ? void 0 : caseRecord.title) !== undefined ? { title: 'otherTitle' } : {})), ((caseRecord === null || caseRecord === void 0 ? void 0 : caseRecord.feedback) ? { feedback: disguise } : {})), ((caseRecord === null || caseRecord === void 0 ? void 0 : caseRecord.customerAddressNumber)
    ? { customerAddressNumber: disguiseAddress }
    : {})), ((caseRecord === null || caseRecord === void 0 ? void 0 : caseRecord.differentBillingAddress) ? {} : {})), ((caseRecord === null || caseRecord === void 0 ? void 0 : caseRecord.productNumber) ? { productNumber: null } : {})), ((caseRecord === null || caseRecord === void 0 ? void 0 : caseRecord.commissionExtraDamage)
    ? { commissionExtraDamage: null }
    : {})), ((caseRecord === null || caseRecord === void 0 ? void 0 : caseRecord.commissionReport) ? { commissionReport: null } : {})), ((caseRecord === null || caseRecord === void 0 ? void 0 : caseRecord.finalStatusReport) !== undefined
    ? { finalStatusReport: {} }
    : {})), ((caseRecord === null || caseRecord === void 0 ? void 0 : caseRecord.invoiceDocument) ? { invoiceDocument: null } : {})), ((caseRecord === null || caseRecord === void 0 ? void 0 : caseRecord.invoiceId) ? { invoiceId: null } : {})), ((caseRecord === null || caseRecord === void 0 ? void 0 : caseRecord.totalAmount) ? { totalAmount: -1 } : {})), ((caseRecord === null || caseRecord === void 0 ? void 0 : caseRecord.baseAmount) ? { baseAmount: -1 } : {})), ((caseRecord === null || caseRecord === void 0 ? void 0 : caseRecord.id) ? { id: -1 } : {}))) ||
    caseRecord;
function isDisguisableAddress(obj) {
    return (isDisguisableCustomer(obj) ||
        !!(obj === null || obj === void 0 ? void 0 : obj.latitude) ||
        !!(obj === null || obj === void 0 ? void 0 : obj.longitude) ||
        !!(obj === null || obj === void 0 ? void 0 : obj.customerAddressStreet) ||
        !!(obj === null || obj === void 0 ? void 0 : obj.customerAddressNumber));
}
export const disguiseCustomerAndAddress = (caseRecord, { disguise, disguiseAddress } = getDefaultDisguiseProps()) => (isDisguisableAddress(caseRecord) && Object.assign(Object.assign(Object.assign(Object.assign({}, disguiseCustomer(caseRecord, { disguise, disguiseAddress })), disguiseLocationAndAddress(caseRecord, 100)), ((caseRecord === null || caseRecord === void 0 ? void 0 : caseRecord.customerAddressStreet)
    ? { customerAddressStreet: disguiseAddress }
    : {})), ((caseRecord === null || caseRecord === void 0 ? void 0 : caseRecord.customerAddressNumber)
    ? { customerAddressNumber: disguiseAddress }
    : {}))) ||
    caseRecord;
export const generateDummyCommisioningId = (dateFunc) => new Date(dateFunc())
    .toISOString()
    .replace(/[-:]/gi, '')
    .replace(/(\d+)T(\d+)\.(\d+).*/gi, '$1-$2-$3');
export function getClaimReference(commissioningReference) {
    var _a;
    const claimReferenceRegex = /\d{7,12}-\d{3,7}/;
    const claimReference = (_a = commissioningReference.match(claimReferenceRegex)) === null || _a === void 0 ? void 0 : _a[0];
    return claimReference || commissioningReference;
}
export function getCompanyTypeWithContactManagerId(commissioningReference) {
    var _a;
    const companyTypeWithContactManagerIdRegex = /(ASP|SWA)-\d+(?=\b)/;
    const companyTypeWithContactManagerId = (_a = commissioningReference.match(companyTypeWithContactManagerIdRegex)) === null || _a === void 0 ? void 0 : _a[0];
    return companyTypeWithContactManagerId || commissioningReference;
}
