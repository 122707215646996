import {
  AuthenticationMode,
  AvailableNotificationSettingValues as Channel,
} from '@adac/core-model';
import { useCallback, useEffect, useState } from 'react';
import { getSignInUserSetting } from '../networking/settings';

const initialSettings = {
  [AuthenticationMode.SIGN_IN_CHANNEL]: Channel.SMS,
};

export function useSettings(userId: number) {
  const [isLoading, setIsLoading] = useState(true);
  const [settings, setSettings] = useState(initialSettings);

  const fetchSettings = useCallback(async () => {
    try {
      const settings = await getSignInUserSetting(userId);
      if (settings[AuthenticationMode.SIGN_IN_CHANNEL]) {
        setSettings(settings);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [userId]);

  useEffect(() => {
    fetchSettings();
  }, [fetchSettings]);

  return {
    settings,
    isLoading,
  };
}
