// eslint-disable-next-line @typescript-eslint/no-unused-vars
import {
  MuiPickersUtilsProvider,
  KeyboardDateTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { MuiPickersUtilsProviderProps } from '@material-ui/pickers/MuiPickersUtilsProvider';
import { __, config } from '@adac/core-model';
import { Children } from '@adac/core-view';
import { Moment } from 'moment';
import MomentUtils from '@date-io/moment';

type DateFormat = 'datetime' | 'date';
export interface DatePickerInputProps {
  placeHolder: string;
  value: Date | null;
  onChange: (value: Date | null) => void;
  format?: DateFormat;
  ampm?: boolean;
}

const useFormat = (format: DateFormat) => {
  switch (format) {
    case 'date':
      return {
        formatString: config.dateEditFormat,
        Component: KeyboardDatePicker,
        getProps: {},
      };
    default:
      return {
        formatString: config.timestampEditFormat,
        Component: KeyboardDateTimePicker,
        getProps: { ampm: false },
      };
  }
};

export const DatePickerInput = ({
  placeHolder,
  value,
  onChange,
  format = 'datetime',
}: DatePickerInputProps) => {
  const { Component, getProps, formatString } = useFormat(format);

  return (
    <Component
      label={`${__(placeHolder)}${value ? ` (${formatString})` : ''}`}
      value={value && new Date(value)}
      onChange={(e: Moment | null) => {
        // on clearing
        if (e === null) return onChange(e);

        // error handling to revert back to the prev value, if the newly overridden data is not dime formattable
        const newValue = e.toISOString() ? new Date(e.toISOString()) : value;
        return onChange(newValue);
      }}
      variant='dialog'
      // ampm={false}
      // onError={console.log}
      showTodayButton
      todayLabel={`[${__('Today')}]`}
      autoOk
      okLabel={`[${__('Save')}]`}
      cancelLabel={`[${__('Cancel')}]`}
      clearable
      clearLabel={`[${__('Delete')}]`}
      maxDate={new Date('2222-12-31 23:59')}
      format={formatString}
      {...getProps}
    />
  );
};

interface DatePickerUtilsProviderProps
  extends Omit<MuiPickersUtilsProviderProps, 'utils'> {
  children: Children;
}
export const DatePickerUtilsProvider = (
  props: DatePickerUtilsProviderProps
) => <MuiPickersUtilsProvider {...props} utils={MomentUtils} />;
