/* eslint-disable import/no-extraneous-dependencies */

import { useState } from 'react';

import { CaseTypeHandler, SubTitle } from '@adac/core-view';
import { __ } from '@adac/core-model';

import { SimpleHeader } from '../common/Header';
import CustomCard from '../common/CustomCard';
import NewRegularCase from './NewRegularCase';

export default (raProps: any) => {
  const [caseType, setCaseType] = useState('save');

  return (
    <CustomCard>
      <SimpleHeader {...raProps} title={`${__('New Test Case')}`}>
        <SubTitle>
          <span role='img' aria-label='warning sign'>
            ⚠️
          </span>
          {__('for testing purpose only')}!
        </SubTitle>
      </SimpleHeader>
      <CaseTypeHandler onChange={setCaseType} value={caseType} />
      <NewRegularCase {...raProps} caseType={caseType} />
    </CustomCard>
  );
};
