import { Company, getApiRoutes } from '@adac/core-model';
import { request } from '@adac/core-view';

export function requestCompanyList() {
  const url = getApiRoutes().companyAdmin.list;
  return request<Company[]>(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
export function requestActiveCompanyList() {
  const url = getApiRoutes().companyAdmin.listActive;
  return request<Company[]>(url, {
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
