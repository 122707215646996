export const BAD_REQUEST = 'BadRequest';
export const UNAUTHORIZED_ERROR = 'UnauthorizedError';
export const FORBIDDEN_ERROR = 'ForbiddenError';
export const PAGE_NOT_FOUND = 'PageNotFound';
export const VALIDATION_ERROR = 'ValidationError';
export const VERSION_ERROR = 'VersionError';
export const SERVER_ERROR = 'ServerError';
export const CLIENT_ERROR = 'ClientError';
export const SERVICE_UNAVAILABLE = 'Service Unavailable';
export const LOCATION_NOT_FOUND = 'LocationNotFound';
