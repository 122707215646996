import React from 'react';
import { Form, Field, FieldProps, useFormikContext } from 'formik';
import ScheduleIcon from '@material-ui/icons/AddAlarm';

import {
  TextField,
  Button,
  ColumnLayout,
  FullWidthLayout,
  ButtonTitle,
  SubTitle,
  ErrorMessage,
  WarningMessage,
  Switch,
  Text,
  getCompanyStatusText,
  useGermanPhoneValidation,
  ConfirmPhoneNumber,
  getCompanyTypeText,
  AddressFieldWithMap,
  Children,
} from '@adac/core-view';

import {
  __,
  CompanyFormValues,
  isCompanyActive,
  CompanyTypes,
  sanitizePhoneNumber,
} from '@adac/core-model';

import Header from '../common/Header';

import { FormStyles, FormLayout } from '../common/FormStyles';

import {
  DatePickerInput,
  DatePickerUtilsProvider,
} from '../common/DatePickerInput';
import { convertStringToDate } from '../../helpers/date';

interface CompanyFormTypes {
  companyTypes: (CompanyTypes | undefined)[];
  children?: Children;
  onCancel: () => void;
}

export const CompanyForm = ({ children, onCancel }: CompanyFormTypes) => {
  const { setFieldValue, isValid, isSubmitting, errors, values } =
    useFormikContext<CompanyFormValues>();

  const isScheduled = values.isScheduled;
  const setScheduled = (newVal: boolean) =>
    setFieldValue('isScheduled', newVal);
  const showDates =
    isScheduled ||
    (isScheduled === null && (!!values.activeFrom || !!values.activeTo));
  const isActive = isCompanyActive(values) || !showDates;

  const { isInvalidGermanPublicNumber, isInvalidGermanPrivateNumber } =
    useGermanPhoneValidation();

  function onPublicPhoneChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (values.validatePhone === false) {
      setFieldValue('validatePhone', true);
    }
    setFieldValue('publicPhone', sanitizePhoneNumber(e.target.value));
  }

  function onPrivatePhoneChange(e: React.ChangeEvent<HTMLInputElement>) {
    if (values.validatePhone === false) {
      setFieldValue('validatePhone', true);
    }
    setFieldValue('privatePhone', sanitizePhoneNumber(e.target.value));
  }

  return (
    <Form>
      <Header
        goBack={onCancel}
        title={
          `${values.name} (${getCompanyTypeText(values.type)} )` ||
          __('New company')
        }
        id={`ASD-SD-${values.type}-${values.contactManagerId}, OpenHomeId: #${values.id}`}
      />
      <DatePickerUtilsProvider>
        <FormStyles>
          <FormLayout>
            <Switch
              value='scheduled'
              text={<>{__('Scheduled company')}</>}
              onChange={setScheduled}
              checked={!!isScheduled}
            >
              <Text
                background={isActive ? 'progress' : 'disabled'}
                color={isActive ? 'background' : 'initial'}
                round
                tiny
                padding='8px'
                marginLeft='10px'
                marginRight='auto'
              >
                {getCompanyStatusText(isActive)}
              </Text>
              <ScheduleIcon color={isScheduled ? 'secondary' : 'disabled'} />
            </Switch>

            {showDates && (
              <ColumnLayout ratio='1fr 1fr' gap='20px'>
                <Field
                  name='activeFrom'
                  render={({
                    field,
                  }: FieldProps<'activeFrom', CompanyFormValues>) => (
                    <DatePickerInput
                      placeHolder='Valid from'
                      value={convertStringToDate(field.value)}
                      onChange={(value) => setFieldValue('activeFrom', value)}
                    />
                  )}
                />
                <Field
                  name='activeTo'
                  render={({
                    field,
                  }: FieldProps<'activeTo', CompanyFormValues>) => (
                    <DatePickerInput
                      placeHolder='Valid to'
                      value={convertStringToDate(field.value)}
                      onChange={(value) => setFieldValue('activeTo', value)}
                    />
                  )}
                />
              </ColumnLayout>
            )}

            <Text>{__('Credentials')}</Text>

            <SubTitle>{__('Company Boss')}</SubTitle>
            <ColumnLayout ratio='1fr 1fr' gap='20px'>
              <Field
                name='firstName'
                render={({
                  field,
                }: FieldProps<'firstName', CompanyFormValues>) => (
                  <TextField
                    {...field}
                    isValid={!errors.firstName}
                    badgeEqualsPlaceholder
                    badgeTitle={__('First Name')}
                  />
                )}
              />
              <Field
                name='familyName'
                render={({
                  field,
                }: FieldProps<'familyName', CompanyFormValues>) => (
                  <TextField
                    {...field}
                    isValid={!errors.familyName}
                    badgeEqualsPlaceholder
                    badgeTitle={__('Last Name')}
                  />
                )}
              />
            </ColumnLayout>
            {errors.firstName && (
              <ErrorMessage>{__(errors.firstName)}</ErrorMessage>
            )}
            {errors.familyName && (
              <ErrorMessage>{__(errors.familyName)}</ErrorMessage>
            )}

            <SubTitle>{__('Availability')}</SubTitle>
            <ColumnLayout ratio='1fr 1fr' gap='20px'>
              <Field
                name='publicPhone'
                render={({
                  field,
                }: FieldProps<'publicPhone', CompanyFormValues>) => (
                  <TextField
                    {...field}
                    isValid={!errors.publicPhone}
                    onChange={(e) => onPublicPhoneChange(e)}
                    badgeEqualsPlaceholder
                    badgeTitle={__('Public phone')}
                  />
                )}
              />
              <Field
                name='privatePhone'
                render={({
                  field,
                }: FieldProps<'privatePhone', CompanyFormValues>) => (
                  <TextField
                    disabled
                    {...field}
                    isValid={!errors.privatePhone}
                    onChange={(e) => onPrivatePhoneChange(e)}
                    badgeEqualsPlaceholder
                    badgeTitle={__('Private phone')}
                  />
                )}
              />
            </ColumnLayout>

            <ConfirmPhoneNumber
              confirm={() => setFieldValue('validatePhone', false)}
              canBeConfirmed={isInvalidGermanPublicNumber}
              errors={[errors.publicPhone]}
            />
            <ConfirmPhoneNumber
              confirm={() => setFieldValue('validatePhone', false)}
              canBeConfirmed={isInvalidGermanPrivateNumber}
              errors={[errors.privatePhone]}
            />

            <WarningMessage>
              {__('Warning: used for SWA email sending')}
            </WarningMessage>

            <ColumnLayout ratio='1fr 1fr' gap='20px'>
              <Field
                name='username'
                render={({
                  field,
                }: FieldProps<'username', CompanyFormValues>) => (
                  <TextField
                    {...field}
                    isValid={!errors.username}
                    badgeEqualsPlaceholder
                    badgeTitle={__('UserName')}
                  />
                )}
              />
              <Field
                name='role'
                render={({ field }: FieldProps<'role', CompanyFormValues>) => (
                  <TextField
                    {...field}
                    value={__('Role:company')}
                    disabled
                    badgeEqualsPlaceholder
                    badgeTitle={__('Role')}
                  />
                )}
              />
            </ColumnLayout>
            {errors.username && (
              <ErrorMessage>{__(errors.username)}</ErrorMessage>
            )}

            <ColumnLayout ratio='1fr 1fr' gap='20px'>
              <Field
                name='password'
                render={({
                  field,
                }: FieldProps<'password', CompanyFormValues>) => (
                  <TextField
                    {...field}
                    isValid={!errors.password}
                    type='password'
                    badgeEqualsPlaceholder
                    badgeTitle={__('Password')}
                  />
                )}
              />
              <Field
                name='confirmPassword'
                render={({
                  field,
                }: FieldProps<'confirmPassword', CompanyFormValues>) => (
                  <TextField
                    {...field}
                    isValid={!errors.confirmPassword}
                    type='password'
                    badgeEqualsPlaceholder
                    badgeTitle={__('Confirm password')}
                  />
                )}
              />
            </ColumnLayout>
            {errors.password && (
              <ErrorMessage>{__(errors.password)}</ErrorMessage>
            )}
            {errors.confirmPassword && (
              <ErrorMessage>{__(errors.confirmPassword)}</ErrorMessage>
            )}

            <AddressFieldWithMap />

            <ColumnLayout ratio='1fr 1fr'>
              <FullWidthLayout style={{ width: 'auto' }}>
                <Button
                  type='button'
                  info
                  disabled={isSubmitting}
                  onClick={() => onCancel()}
                >
                  <ButtonTitle>{__('Cancel')}</ButtonTitle>
                </Button>
              </FullWidthLayout>

              <FullWidthLayout>
                <Button
                  type='submit'
                  cta
                  disabled={isSubmitting || !isValid}
                  isLoading={isSubmitting}
                  title={__('Update')}
                />
              </FullWidthLayout>
            </ColumnLayout>

            {children && <ColumnLayout ratio='1fr'>{children}</ColumnLayout>}
          </FormLayout>
        </FormStyles>
      </DatePickerUtilsProvider>
    </Form>
  );
};
