export function keyGenerator(props, keys = []) {
    const defaultKeys = ['key', 'id', 'component', 'path'];
    const allKeys = defaultKeys.concat(keys);
    const value = (Object.entries(props).find(([key]) => allKeys.includes(key)) || [null, props])[1];
    if (Array.isArray(value))
        return JSON.stringify(value);
    return value;
}
export const updateAtIndex = (array, newValue, index) => [
    ...array.slice(0, index),
    newValue,
    ...array.slice(index + 1),
];
export const toggleArrayItem = (items, item) => items.includes(item)
    ? items.reduce((newItems, currItem) => [
        ...newItems,
        ...(currItem === item ? [] : [currItem]),
    ], [])
    : [...items, item];
