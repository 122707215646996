import { JobDoorImages } from '@adac/core-model';
import styled from 'styled-components';

interface FullScreenPhotoProps {
  imageData: JobDoorImages | undefined;
  width?: string;
  isOpen: boolean;
  onClose: () => void;
}

const Overlay = styled.div<{ isOpen: boolean }>`
  display: ${({ isOpen }) => (isOpen ? 'flex' : 'none')};
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ImageContainer = styled.div`
  position: relative;
  max-width: 100%;
  max-height: 100%;
`;

const Image = styled.img<{ width?: string }>`
  height: 100%;
  width: ${({ width }) => width || '100%'};
  border-radius: 8px;
`;

const Caption = styled.div`
  color: white;
  text-align: center;
  margin-top: 10px;
`;

const CloseButton = styled.button`
  position: absolute;
  top: 20px;
  right: 20px;
  background: none;
  border: none;
  color: black;
  font-size: 24px;
  cursor: pointer;
`;

export const FullScreenPhoto = ({
  imageData,
  width,
  isOpen,
  onClose,
}: FullScreenPhotoProps) => (
  <Overlay isOpen={isOpen}>
    <ImageContainer>
      <CloseButton type='button' onClick={onClose}>
        &times;
      </CloseButton>
      <Image src={imageData?.image} alt={imageData?.text} width={width} />
      <Caption>{imageData?.text}</Caption>
    </ImageContainer>
  </Overlay>
);
