import { Driver, UserRole, hasUserRole } from '@adac/core-model';
import { EditButton } from 'react-admin';
import { useUserRole } from '../../../helpers/useUserRole';

type EditButtonProps = {
  basePath?: string;
  className?: string;
  classes?: object;
  label?: string;
  record?: object;
  icon?: JSX.Element;
};

interface EditButtonWithRoleProps extends EditButtonProps {
  role?: UserRole;
}

export const EditButtonWithRole = ({
  role,
  ...props
}: EditButtonWithRoleProps) => {
  const userRole = useUserRole();
  const editRole = role || UserRole.MANAGER;
  const companyId = (props.record as Driver).companyId;
  if (hasUserRole(userRole.names, editRole))
    return (
      <EditButton
        {...props}
        basePath={props.basePath?.replace(':companyId', `${companyId}`)}
      />
    );
  return null;
};
