import { getApiRoutes, AdminLogRecord } from '@adac/core-model';
import { request } from '@adac/core-view';
import { DateFilterProps } from '../components/statistics/FilterForm';
import { getHeader } from './requestCreators';

// NOTE: CRA develpoment server has 2 minute timeout anyway https://github.com/axios/axios/issues/1873#issuecomment-617087449
const timeout = 300 * 1000; // 5 minutes

export interface ReportUrl {
  url: string;
}

export const getFullReportCSVUrl = async (params: string[]) =>
  request<ReportUrl, DateFilterProps>(getApiRoutes().statistics.full(params), {
    headers: getHeader(),
    timeout,
  });

export const getPreviousFullReportUrls = async (userId: number) =>
  request<AdminLogRecord[]>(
    getApiRoutes().backoffice.adminLog.getLatest('report', userId),
    {
      headers: getHeader(),
    }
  );

// export const getEstimationsCSVUrl = async () => request<ReportUrl>(getApiRoutes().statistics.timeSuggestions, undefined, getHeader(), undefined, undefined, timeout);
