import { BackofficeUser } from '@adac/core-model';
import { getBackofficeUser } from '@adac/core-view';
import { useCallback, useEffect, useState } from 'react';

export function useMe() {
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState<BackofficeUser | undefined>(undefined);

  const fetchUser = useCallback(async () => {
    try {
      const me = await getBackofficeUser();
      setUser(me);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  return {
    user,
    isLoading,
  };
}
