import __ from '../helpers/i18n.js';
export const commonCancellationReasons = [
    {
        id: 'Could help myself',
        title: __('Could help myself'),
        value: 'Could help myself',
        fee: true,
    },
    {
        id: 'Found key',
        title: __('Found key'),
        value: 'Found key',
        fee: true,
    },
    {
        id: 'Waittime',
        title: __('Waittime'),
        value: 'Waittime',
        fee: true,
    },
    {
        id: 'Price',
        title: __('Price'),
        value: 'Price',
        fee: true,
    },
];
export const otherCancellationReason = {
    id: 'Other',
    title: __('Other'),
    value: 'Other',
    fee: true,
};
export const noSNDAvailableCancellationReason = {
    id: 'No SND available',
    title: __('No SND available'),
    value: 'No SND available',
};
export const backofficeCancellationReasons = [
    ...commonCancellationReasons,
    otherCancellationReason,
    noSNDAvailableCancellationReason,
];
export const customerCancellationReasons = [
    ...commonCancellationReasons,
    otherCancellationReason,
];
