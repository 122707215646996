import { EscalationData } from '@adac/core-model';

export const SET_CLOSING_CASE = 'SET_CLOSING_CASE';

export type CaseCloseType = EscalationData | null | false;

export default (caseToClose: CaseCloseType) => ({
  type: SET_CLOSING_CASE,
  payload: caseToClose,
});
