import styled from 'styled-components';
import TrashIcon from '@material-ui/icons/Delete';

import { View, Text, ButtonProps, Button } from '@adac/core-view';
import { __ } from '@adac/core-model';

export const DeleteButtonStyles = styled(View)`
  display: grid;
  place-items: center;
`;
export const DeleteButtonContentStyles = styled(View)`
  display: flex;
  align-items: center;
`;
export const DeleteButtonText = styled(Text)`
  font-weight: bold;
  color: ${(props) => props.theme.colors.warning};
`;

export interface DeleteButtonProps extends ButtonProps {
  onClick: () => void;
}

/* eslint-disable no-alert */
export const DeleteButton = ({
  onClick,
  type,
  title,
  ...props
}: DeleteButtonProps) => {
  const handleDelete = () => {
    if (!onClick || !window.confirm(__('Are you sure?'))) return;
    onClick();
  };

  return (
    <DeleteButtonStyles>
      <Button onClick={handleDelete} type={type} {...props}>
        <DeleteButtonContentStyles>
          <DeleteButtonText>{title || __('Delete')}</DeleteButtonText>
          <TrashIcon />
        </DeleteButtonContentStyles>
      </Button>
    </DeleteButtonStyles>
  );
};
