import { getApiRoutes, User } from '@adac/core-model';
import { request } from '@adac/core-view';

export function getBlockedList() {
  const url = getApiRoutes().backoffice.users.listBlocked;
  return request<User[]>(url, {
    method: 'GET',
  });
}

export function unblockUser(userId: number) {
  const url = getApiRoutes().auth.users.unblock(userId);
  return request(url, {
    method: 'PUT',
    headers: {
      'Content-Type': 'application/json',
    },
  });
}
