import { createContext, useContext } from 'react';
import {
  UIStore,
  AuthStore,
  themeConfig,
  getBackofficeUser,
  Children,
} from '@adac/core-view';
import { BackofficeUser, getApiRoutes } from '@adac/core-model';

export interface Stores {
  ui: UIStore;
  auth: AuthStore<BackofficeUser>;
}

const uiStore = new UIStore(themeConfig);
const authStore = new AuthStore(
  getApiRoutes().auth.loginToBackoffice,
  getBackofficeUser
);
const stores: Stores = {
  ui: uiStore,
  auth: authStore,
};

// Use the React context API so that the stores are available anywhere in the app
const StoresContext = createContext(stores);
export default StoresContext;

export { stores };

export const useAuthStore = () => {
  const { auth: authStore } = useContext(StoresContext);
  return authStore;
};

export const useOverlay = () => {
  const { ui: uiStore } = useContext(StoresContext);
  return (c?: Children) => uiStore.setOverlay(c);
};
