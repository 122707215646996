/* eslint-disable import/no-extraneous-dependencies */

import {
  Edit,
  getResources,
  showNotification as showNotificationAction,
  UPDATE,
  DELETE,
} from 'react-admin';
import { connect } from 'react-redux';

import {
  DriverDeleteButton,
  useValidationSchema,
  ManageDriver,
} from '@adac/core-view';
import { Driver, DriverRecord, getApiRoutes, UserRole } from '@adac/core-model';
import { withRouter } from 'react-router';
import Header from '../common/Header';
import dataProvider from '../../dataProvider';
import CardContent from './CardContent';
import { RARecordProps, FormProps } from '../common/react-admin/interfaces';
import { useDataProviderRecord } from '../../dataProvider/useDataProviderRecord';
import { useRouterParamCompanyId } from '../../helpers/useRouterParam';

const DriverEditForm = (raProps: FormProps<Driver>) => {
  const driver = useDataProviderRecord<DriverRecord>(
    raProps.resource,
    raProps.record.id
  );
  const companyId = Number(useRouterParamCompanyId(raProps));
  const validationSchema = useValidationSchema(driver);

  const goBack = () =>
    raProps.history.push(
      getApiRoutes(false, '').companyAdmin.editDriversList(companyId)
    );

  const onSubmit = async (driverParam: any) => {
    try {
      await dataProvider(UPDATE, raProps.resource, {
        ...driverParam,
        id: driver?.id,
      });
      goBack();
    } catch (error) {
      console.log('error', error);
      raProps.showNotification(error?.toString() ?? `${error}`);
    }
  };

  const onDeleteUser = async () => {
    try {
      await dataProvider(DELETE, raProps.resource, { id: driver?.id });
      goBack();
    } catch (error) {
      raProps.showNotification(error?.toString() ?? `${error}`);
    }
  };

  return (
    <>
      <Header
        goBack={goBack}
        title={`${raProps.record.firstName} ${raProps.record.familyName}`}
      />
      <CardContent>
        {driver && (
          <ManageDriver
            companyId={driver.companyId}
            driver={driver}
            onSubmit={onSubmit}
            onBack={goBack}
            validationSchema={validationSchema}
            disablePrivatePhoneForBoss
          >
            {!driver?.user?.roles?.find(
              (role) => role.name === UserRole.COMPANY
            ) && <DriverDeleteButton onClick={onDeleteUser} />}
          </ManageDriver>
        )}
      </CardContent>
    </>
  );
};

const mapEditStateToProps = (state: any) => ({
  resources: getResources(state),
});

const ConnectedDriverEditForm = connect(mapEditStateToProps, {
  showNotification: showNotificationAction,
})(withRouter(DriverEditForm));

export default (raProps: RARecordProps<Driver>) => (
  <Edit {...raProps}>
    <ConnectedDriverEditForm {...raProps} />
  </Edit>
);
