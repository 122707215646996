import {
  AcceptConditionsComponent,
  ChildrenProps,
  filterConsentCheckboxItems,
  getConsentCheckboxes,
  Loader,
  LoadingSpinner,
  Span,
  TACAndPrivacyPlaceholder,
} from '@adac/core-view';

import {
  __,
  CaseRecord,
  extraDamageReportConsents,
  getFullName,
  openingReportConsents,
  ProductTypes,
} from '@adac/core-model';

import moment from 'moment';
import CaseStatusInfoBlock, {
  CaseDetailsTitleWithText,
} from './CaseStatusInfoBlock';
import CaseStatusSection from './CaseStatusSection';

interface CaseStatusDetailsProps extends ChildrenProps {
  isLoading: boolean;
  caseDetails?: CaseRecord;
}

const CaseStatusDetails = ({
  isLoading,
  caseDetails,
  children,
}: CaseStatusDetailsProps): JSX.Element => {
  if (!caseDetails) {
    return <LoadingSpinner>{__('Loading')}</LoadingSpinner>;
  }

  const checkboxItems = getConsentCheckboxes(
    caseDetails?.productType,
    caseDetails?.consents
  );

  return (
    <CaseStatusSection
      padding='40px 0 0 !important'
      title='Informationen über den Kunden'
    >
      <>{children}</>
      <CaseStatusInfoBlock area='name' title={__('Name')}>
        <Loader isLoading={isLoading}>{getFullName(caseDetails)}</Loader>
      </CaseStatusInfoBlock>
      <CaseStatusInfoBlock area='phone' title={__('Handy-Nummer')}>
        <Loader isLoading={isLoading}>
          {caseDetails?.customerPhone || ''}
        </Loader>
      </CaseStatusInfoBlock>
      <CaseStatusInfoBlock area='email' title={__('Email')}>
        <Loader isLoading={isLoading}>
          {caseDetails?.customerEmail || ''}
        </Loader>
      </CaseStatusInfoBlock>
      <CaseStatusInfoBlock area='productType' title={__('ProductType')}>
        <Loader isLoading={isLoading}>
          {__(`ProductType:${caseDetails?.productType}`) ||
            __('No product type')}{' '}
          #{caseDetails?.productNumber || __('No product number')}
        </Loader>
      </CaseStatusInfoBlock>
      <>
        {caseDetails?.productType === ProductTypes.INSURANCE && (
          <CaseStatusInfoBlock area='damageNumber' title={__('damageNumber')}>
            <Loader isLoading={isLoading}>
              {caseDetails?.damageNumber || __('No product number')}
            </Loader>
          </CaseStatusInfoBlock>
        )}
      </>
      <CaseStatusInfoBlock area='appointment' title={__('Appointment')}>
        <Loader isLoading={isLoading}>
          {caseDetails?.scheduledTs ? (
            <>
              <Span small>
                {moment(caseDetails?.scheduledTs).format('DD.MM.YY')} um{' '}
              </Span>
              <Span small>
                {moment(caseDetails?.scheduledTs).format('HH.mm')} Uhr{' '}
              </Span>
            </>
          ) : (
            '-'
          )}
        </Loader>
      </CaseStatusInfoBlock>
      <CaseStatusInfoBlock area='complexity' title={__('Complexity')}>
        <Loader isLoading={isLoading}>
          {__(`complexity:${caseDetails?.complexity}`)}
        </Loader>
      </CaseStatusInfoBlock>

      <CaseDetailsTitleWithText area='accepted'>
        <Loader isLoading={isLoading} component={TACAndPrivacyPlaceholder}>
          <AcceptConditionsComponent
            items={filterConsentCheckboxItems(
              checkboxItems,
              caseDetails?.productType,
              openingReportConsents
            )}
            readOnly
          />
          {caseDetails.commissionExtraDamage && (
            <AcceptConditionsComponent
              items={filterConsentCheckboxItems(
                checkboxItems,
                caseDetails?.productType,
                extraDamageReportConsents
              )}
              readOnly
            />
          )}
        </Loader>
      </CaseDetailsTitleWithText>
    </CaseStatusSection>
  );
};

export default CaseStatusDetails;
