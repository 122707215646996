import React from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import { Route, Switch } from 'react-router-dom';
import { observer } from 'mobx-react';

import { keyGenerator } from '@adac/core-model';
import LoginPage from './Login';
import ForgotPassword from './ForgotPassword';

export enum PathNames {
  FORGOT_PASSWORD = '/forgot-password',
  CONFIRM_TOKEN = '/confirm-token',
}

export default observer((): JSX.Element => {
  const routes: JSX.Element[] = [
    <Route
      path={PathNames.FORGOT_PASSWORD}
      exact
      pageTransition='slideLeft'
      component={ForgotPassword}
    />,
    <Route path='/' component={LoginPage} />,
  ];

  return (
    <Switch>
      {routes.map((RouteConfig) =>
        React.cloneElement(RouteConfig, {
          key: keyGenerator(RouteConfig.props),
        })
      )}
    </Switch>
  );
});
