export var PriceTypes;
(function (PriceTypes) {
    PriceTypes["WEEKDAY"] = "WEEKDAY";
    PriceTypes["OTHER"] = "OTHER";
    PriceTypes["DEFECTIVE"] = "DEFECTIVE";
    PriceTypes["LOCK"] = "LOCK";
    PriceTypes["HARDWARE"] = "HARDWARE";
    PriceTypes["SNDFEE"] = "SNDFEE";
    PriceTypes["INCIDENTAL_ITEMS"] = "INCIDENTAL_ITEMS";
    PriceTypes["MILLING"] = "MILLING";
    PriceTypes["CANCELFEE"] = "CANCELFEE";
    PriceTypes["OTHER_EXTRA"] = "OTHER_EXTRA";
    PriceTypes["SECURITY_FITTING"] = "SECURITY_FITTING";
})(PriceTypes = PriceTypes || (PriceTypes = {}));
