export var CompanyTypes;
(function (CompanyTypes) {
    CompanyTypes["EXT"] = "ASP";
    CompanyTypes["ADAC"] = "SWA";
})(CompanyTypes = CompanyTypes || (CompanyTypes = {}));
export const companyStatuses = Object.freeze({
    inactive: 'INACTIVE',
    active: 'ACTIVE',
});
export const activeCompanyTypes = [
    CompanyTypes.EXT,
    CompanyTypes.ADAC,
];
export const informOtherDispacherCompanyTypes = [
    CompanyTypes.ADAC,
];
export const complexCaseAllowedCompanyTypes = [
    CompanyTypes.EXT,
];
export const allCompanyTypes = activeCompanyTypes;
export const newCompanyTypes = allCompanyTypes;
export var TimeSuggestionAction;
(function (TimeSuggestionAction) {
    TimeSuggestionAction["REJECT"] = "reject";
    TimeSuggestionAction["ACCEPT"] = "accept";
})(TimeSuggestionAction = TimeSuggestionAction || (TimeSuggestionAction = {}));
