export const formatTime = (ms) => {
    const time = {
        days: Math.floor(ms / 86400000),
        hours: Math.floor(ms / 3600000) % 24,
        minutes: Math.floor(ms / 60000) % 60,
        seconds: Math.floor(ms / 1000) % 60,
        milliseconds: Math.floor(ms) % 1000,
    };
    return time;
};
export const formatStopwatch = (time, { hour = false, minute = true, second = true } = {}) => {
    if (time === null)
        return '';
    const hourString = hour || time.hours || time.days
        ? `${time.hours + time.days * 24}`.padStart(2, '0')
        : '';
    const minuteString = minute || time.minutes ? `${time.minutes}`.padStart(2, '0') : '';
    const secondString = second || time.seconds ? `${time.seconds}`.padStart(2, '0') : '';
    return [hourString, minuteString, secondString]
        .filter((str) => str)
        .join(':');
};
