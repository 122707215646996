import styled from 'styled-components';

import { View } from '@adac/core-view';

export default styled(View)`
  & > * {
    margin: 4em auto;
    display: block;
    width: 50%;
  }
`;
