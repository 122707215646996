import { i18n, nullableI18n as nI18n } from './i18n-helpers.js';
import localization from '../config/i18n.json' assert { type: 'json' };
const defaultI18n = (title, language = process.env.REACT_APP_LANGUAGE || '', myLocalization = localization) => i18n(title, language, myLocalization);
export const defaultNullableI18n = (title, language = process.env.REACT_APP_LANGUAGE || '', myLocalization = localization) => nI18n(title, language, myLocalization);
export function i18nTemplate(errorMessage, templateValues) {
    const template = defaultNullableI18n(errorMessage);
    if (!template)
        return errorMessage;
    const getValue = (key) => typeof templateValues[key] === 'string'
        ? defaultI18n(String(templateValues[key]))
        : String(templateValues[key]);
    const templatedTranslation = Object.keys(templateValues).reduce((templateString, key) => templateString.replace(`{${key}}`, getValue(key)), template);
    return templatedTranslation;
}
export default defaultI18n;
