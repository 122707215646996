import { USERS_BLOCKED } from '../dataProvider/resources';

interface Props {
  type: string;
  payload: any;
}

// eslint-disable-next-line default-param-last
export default (previousState = null, { payload, type }: Props) => {
  if (type === USERS_BLOCKED) {
    return payload;
  }
  return previousState;
};
