export const SND_COMPANIES = 'admin/backoffice/snd-companies';
export const DRIVERS = 'admin/backoffice/drivers';
export const SND_COMPANY_DRIVERS = `${SND_COMPANIES}/:companyId/${DRIVERS}`;
export const USERS_BACKOFFICE = 'admin/users/backoffice';
export const USERS_BLOCKED = 'admin/users/blocked';
export const USERS_COMPANY = 'admin/users/company';
export const OPEN_CASES = 'admin/cases/open';
export const CLOSED_CASES = 'admin/cases/closed';
export const NEW_CASE = 'admin/backoffice';
export const EDIT_CASE = 'admin/backoffice/:id';
export const HOLIDAYS = 'admin/backoffice/holidays';
export const SETTINGS = 'admin/backoffice/settings';
export const BENCHMARK = 'admin/backoffice/benchmark';
export const ADMIN_LOGS = 'admin/backoffice/adminlog';
export const ZIPCODES = 'admin/backoffice/zip-codes';
