/* eslint-disable react/jsx-no-bind */
import { CircleLoader, Modal, View } from '@adac/core-view';
import { Form, Formik } from 'formik';
import styled from 'styled-components';
import { __ } from '@adac/core-model';
import { useImageReports } from '../../../hooks/useImageReport';
import { useImageReportFormikProps } from '../../../hooks/useImageReportFormikProps';
import { EditReportForm } from './EditReportForm';
import { EditFormButtons } from './EditFormButtons';

const EditModal = styled(Modal)`
  position: static;
  max-width: 800px;
`;

const FormStyles = styled(View)`
  flex-grow: 1;
  min-height: 0;
  max-height: 500px;
  overflow-y: auto;
  width: 100%;
`;

interface SignatureAndPhotosFormValues {
  photos: boolean[];
  signature: boolean;
}

export interface EditReportImageFormValues {
  comissionOpening: SignatureAndPhotosFormValues;
  commissionExtraDamage?: SignatureAndPhotosFormValues;
  openingCompleted?: SignatureAndPhotosFormValues;
  reason: string[];
  otherReason?: string;
}

interface EditDocumentsModalProps {
  on: boolean;
  caseToken?: string;
  setIsOpen: (b: boolean) => void;
  showNotification: (error: string) => void;
}

export function EditReportModal({
  on,
  setIsOpen,
  caseToken,
  showNotification,
}: EditDocumentsModalProps) {
  const { reports, setReports, updateReports } = useImageReports(caseToken);
  const { initialValues, validationSchema } =
    useImageReportFormikProps(reports);

  async function onSubmit(values: EditReportImageFormValues) {
    try {
      const reportValues = {
        ...values,
        otherReason: undefined,
        reason: values.reason.map((reason) =>
          reason === 'other' ? values.otherReason || __('other') : reason
        ),
      };
      await updateReports(reportValues);
      setIsOpen(false);
    } catch (error) {
      showNotification(error?.toString() ?? `${error}`);
    }
  }

  return (
    <EditModal on={on} close={() => setIsOpen(false)}>
      {reports ? (
        <FormStyles>
          <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={onSubmit}
            isInitialValid={false}
          >
            <Form>
              <EditReportForm reports={reports} setReports={setReports} />
              <EditFormButtons onBack={() => setIsOpen(false)} />
            </Form>
          </Formik>
        </FormStyles>
      ) : (
        <CircleLoader />
      )}
    </EditModal>
  );
}
