/* eslint-disable react/jsx-no-bind */
import { ImageReport, JobDoorImages } from '@adac/core-model';
import { DoorPhoto, themeConfig, View } from '@adac/core-view';
import { useFormikContext } from 'formik';
import { useState } from 'react';
import styled from 'styled-components';
import { EditReportImageFormValues } from './EditReportModal';
import { FullScreenPhoto } from './FullScreenPhoto';
import { censorship } from '../../../helpers/censorship';

export const ScrollList = styled(View)`
  width: 100%;
  margin: 16px 0;
  white-space: nowrap;
  overflow-x: auto;

  display: flex;
  gap: 10px;
  align-items: center;

  & > * {
    height: 150px;
    flex: 0 0 150px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    background-color: ${themeConfig.colors.backgroundLight};
  }
`;

export interface ReportImageProps {
  reports: ImageReport | undefined;
  reportType: keyof ImageReport;
  photos: JobDoorImages[];
  signature: string | undefined;
  setReports: (ir: ImageReport | undefined) => void;
}

export function ReportImages({
  reports,
  reportType,
  photos,
  signature,
  setReports,
}: ReportImageProps) {
  const [fullScreenPhoto, setFullScreenPhoto] = useState<
    JobDoorImages | undefined
  >(undefined);
  const { values, setFieldValue } =
    useFormikContext<EditReportImageFormValues>();

  function onPhotoReplace(photoIndex: number) {
    const censoredImage = censorship.censoredImageUrl;

    if (!reports) {
      console.error('Reports state is undefined.');
      return;
    }

    const currentReportSection = reports[reportType];

    if (
      currentReportSection &&
      currentReportSection.photos &&
      currentReportSection.photos[photoIndex]
    ) {
      const updatedPhotosList = currentReportSection.photos.map(
        (photo, index) =>
          index === photoIndex ? { ...photo, image: censoredImage } : photo
      );

      const updatedReportsState: ImageReport = {
        ...reports,
        [reportType]: {
          ...currentReportSection,
          photos: updatedPhotosList,
        },
      };

      setReports(updatedReportsState);

      setFieldValue(reportType, {
        ...values[reportType],
        photos: values[reportType]?.photos.map((isCensored, index) =>
          index === photoIndex ? true : isCensored
        ),
      });
    } else {
      console.error(
        `Photo at index ${photoIndex} does not exist in ${reportType}.`
      );
    }
  }

  function onSignatureReplace() {
    if (!reports) {
      console.error('Reports state is undefined.');
      return;
    }

    const currentReportSection = reports[reportType];
    if (currentReportSection) {
      const updatedReportsState: ImageReport = {
        ...reports,
        [reportType]: {
          ...currentReportSection,
          signature: censorship.censoredSignatureUrl,
        },
      };

      setReports(updatedReportsState);

      setFieldValue(reportType, {
        ...values[reportType],
        signature: true,
      });
    } else {
      console.error(`Signature does not exist in ${reportType}.`);
    }
  }

  return (
    <View>
      <FullScreenPhoto
        imageData={fullScreenPhoto}
        isOpen={!!fullScreenPhoto}
        onClose={() => setFullScreenPhoto(undefined)}
        width='800px'
      />
      <ScrollList>
        {photos.map((photo, index) => (
          <DoorPhoto
            key={`${photo.image}${index}`}
            src={photo.image}
            index={index + 1}
            onDelete={() => onPhotoReplace(index)}
            onFullScreen={() => setFullScreenPhoto(photo)}
          />
        ))}
        {signature && (
          <DoorPhoto
            src={signature}
            index={photos.length}
            onDelete={onSignatureReplace}
            onFullScreen={() =>
              setFullScreenPhoto({
                image: signature,
                text: `${reportType} signature`,
              })
            }
          />
        )}
      </ScrollList>
    </View>
  );
}
