import { getApiRoutes } from '@adac/core-model';
import { getOptions } from './requestCreators';

import { handleResponseStatus } from './responseHandlers';

export const getCaseDetails = async (token: string) => {
  const url = getApiRoutes().backoffice.case.getCaseByToken(token);

  const newOptions = {
    ...getOptions(),
    method: 'GET',
  };

  const response = await window.fetch(url, newOptions);
  const result = await handleResponseStatus(response);

  return result;
};
