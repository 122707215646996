import {
  CaseActions,
  getApiRoutes,
  EscalationData,
  JobCancelation,
} from '@adac/core-model';
import { getOptions } from './requestCreators';

import { handleResponseStatus } from './responseHandlers';

export const closeCase = async (
  currentCase: EscalationData,
  body: JobCancelation
) => {
  if (!currentCase.token) return null;
  const url = getApiRoutes().backoffice.case.setStatus(
    currentCase.token,
    CaseActions.CLOSE_CASE_MANUAL
  );
  const newOptions = {
    ...getOptions(),
    method: 'POST',
    body: JSON.stringify(body),
  };
  // eslint-disable-next-line no-undef
  const response = await fetch(url, newOptions);
  const result = await handleResponseStatus(response);
  return result;
};
