import { Button as MuiButton } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import { RouteRecordProps } from '../../types/react-router-admin';

export const NotificationSettingsButton = <T extends { id: number }>({
  record,
  history: { push },
}: RouteRecordProps<T>) => (
  <MuiButton onClick={() => push(`snd-companies/notifications/${record.id}`)}>
    <NotificationsIcon />
  </MuiButton>
);
