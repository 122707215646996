const sentry = (envVarSuffix) => {
    const { REACT_APP_SENTRY_LEVELS: sentryLevels, NODE_ENV } = process.env;
    let parsedLevels;
    try {
        parsedLevels = JSON.parse(sentryLevels || '[]');
    }
    catch (err) {
        console.log(err);
        console.log('Sentry Config: REACT_APP_SENTRY_LEVELS should be a valid JSON array');
        parsedLevels = [];
    }
    return {
        sentryUrl: process.env[`REACT_APP_SENTRY_URL_${envVarSuffix}`],
        debug: !!(process.env[`REACT_APP_SENTRY_URL_${envVarSuffix}`] &&
            NODE_ENV === 'development'),
        sentryLevels: parsedLevels,
    };
};
export default sentry;
