import { Filter, TextInput } from 'react-admin';
import { __ } from '@adac/core-model';

export default (props: any) => (
  <Filter {...props}>
    <TextInput label={__('User id')} source='id' allowEmpty />
    <TextInput label={__('Username')} source='username' allowEmpty />
    <TextInput label={__('Name')} source='name' allowEmpty />
  </Filter>
);
