/* eslint-disable import/no-extraneous-dependencies */

import styled from 'styled-components';
import {
  CREATE,
  showNotification as showNotificationAction,
} from 'react-admin';

import {
  Text,
  View,
  ColumnLayout,
  ButtonTitle,
  Button,
  useAsyncComponent,
  OfferPrice,
  useAsyncAction,
  OfferPriceProps,
  getDoorCoordsByAddress,
  extractAddressObject,
  request,
} from '@adac/core-view';

import {
  __,
  getApiRoutes,
  isLocationError,
  BasePriceObj,
  formatLocaleTimestamp,
  CustomerDataWithSms,
} from '@adac/core-model';

import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, withRouter } from 'react-router';
import { SimpleHeader } from '../common/Header';
import CustomCard, { CardContent } from '../common/CustomCard';
import saveManualCase from '../../actions/saveManualCase';

import dataProvider from '../../dataProvider';
import { NEW_CASE, OPEN_CASES } from '../../dataProvider/resources';

import UserDataList from './UserDataList';

import { RAShowNotificationProps } from '../common/react-admin/interfaces';

const OurOfferOuterBox = styled(View)`
  display: grid;
  place-items: center;
`;

const OurOfferInnerBox = styled(View)`
  width: 66%;
`;

const OurOfferTitle = styled(Text)`
  font-weight: bold;
  margin-bottom: 16px;
`;

const OurOfferSubTitle = styled(Text)`
  font-size: 1.4rem;
  color: ${(props) => props.theme.colors.gray};
  margin-top: 8px;
`;

const OurOffer = ({ amount, type, date }: OfferPriceProps) => (
  <OurOfferOuterBox>
    <OurOfferInnerBox>
      <OurOfferTitle>{__('Our Offer')}</OurOfferTitle>
      <OfferPrice amount={amount} type={type} />
      <OurOfferSubTitle>
        {`${__('Time of Assignment in the current case')}:`}{' '}
        {formatLocaleTimestamp(date)}
      </OurOfferSubTitle>
    </OurOfferInnerBox>
  </OurOfferOuterBox>
);

interface NewCasePreviewProps
  extends RAShowNotificationProps,
    RouteComponentProps {
  customerData: CustomerDataWithSms;
  saveCustomerData: (data: CustomerDataWithSms) => void;
}

const NewCasePreview = (props: NewCasePreviewProps) => {
  const { resource: basePriceObj } = useAsyncAction<BasePriceObj>(async () =>
    request(
      getApiRoutes().backoffice.case.basePrice(
        props.customerData.customerAddressZip
      )
    )
  );

  const postCaseAndContinue = async (
    customerData: CustomerDataWithSms,
    history: any,
    saveCustomerData: (data: CustomerDataWithSms) => void
  ) => {
    // NOTE: location might have been changed on the confirmation page, so we revalidate:
    const addressCoords = await getDoorCoordsByAddress(
      extractAddressObject(customerData)
    );

    const customerAddressLocation = isLocationError(addressCoords)
      ? {
          latitude: 0,
          longitude: 0,
        }
      : addressCoords;

    const createdCase = await dataProvider(CREATE, NEW_CASE, {
      data: {
        ...customerData,
        ...customerAddressLocation,
      },
    });

    saveCustomerData({
      ...customerData,
      id: createdCase.data.id,
      token: createdCase.data.token,
      companyId: createdCase.data,
    });

    history.push('/newcase/done');
  };

  const { Component: PostCaseButton } = useAsyncComponent({
    onClick: () =>
      postCaseAndContinue(
        props.customerData as CustomerDataWithSms,
        props.history,
        props.saveCustomerData
      ),
    onError: (error: Error) => {
      console.log(error);
      // const getText = `${error.stack}`.match(/^(.*)\n/gi);
      props.showNotification(__(error?.toString() || `${error}`));
    },
  });

  if (!props.customerData) {
    return (
      <Redirect
        to={{
          pathname: `/${OPEN_CASES}`,
        }}
      />
    );
  }

  return (
    <CustomCard>
      <SimpleHeader {...props} title={__('Case Preview')} />
      <CardContent>
        {basePriceObj ? (
          <OurOffer
            amount={basePriceObj.basePrice}
            type={basePriceObj.basePriceType}
            date={basePriceObj.date}
          />
        ) : (
          <Text>Loading...</Text>
        )}
        <UserDataList customerData={props.customerData} />
        {/* <Text */}
        {/*  paddingBottom="16px" */}
        {/*  fontSize="16px" */}
        {/* > */}
        {/*  <Icon size="20px" name="alert" /> Hinweis für Kunde: Sie erhalten nun eine SMS. Bitte klicken Sie auf den Link in der SMS. Damit wird Ihre Telefonnummer validiert, sodass wir wissen, dass diese tatsächlich existiert. Auf der Seite, zu der Sie der Link führt, müssen Sie den Auftrag bestätigen - erst dann wird der Auftrag tatsächlich ausgelöst! */}
        {/* </Text> */}
        <ColumnLayout ratio='1fr 1fr'>
          <Button
            info
            onClick={() =>
              props.history.push(
                getApiRoutes(false, '').backoffice.case.newCase
              )
            }
          >
            <ButtonTitle>{__('Back')}</ButtonTitle>
          </Button>
          <PostCaseButton cta>
            <ButtonTitle>{__('Confirm Order')}</ButtonTitle>
          </PostCaseButton>
        </ColumnLayout>
      </CardContent>
    </CustomCard>
  );
};

const mapStateToProps = (state: any) => ({ customerData: state.customerData });

export default connect(mapStateToProps, {
  saveCustomerData: saveManualCase,
  showNotification: showNotificationAction,
})(withRouter(NewCasePreview));
