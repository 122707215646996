import { SET_BROWSER_NOTIFICATION } from '../actions/setBrowserNotification';
import { NotificationType } from '../components/admin/BrowserNotification';

// eslint-disable-next-line default-param-last
export default (
  previousState = null,
  { type, payload }: { type: string; payload: NotificationType }
) => {
  if (type === SET_BROWSER_NOTIFICATION) {
    return payload;
  }
  return previousState;
};
