import {
  Create,
  getResources,
  CREATE,
  showNotification as showNotificationAction,
} from 'react-admin';

import { Formik, FormikHelpers as FormikActions } from 'formik';

import { connect } from 'react-redux';

import {
  __,
  CompanyFormValues,
  UserRole,
  newCompanyTypes,
  getApiRoutes,
} from '@adac/core-model';

import dataProvider from '../../dataProvider';

import { CompanyForm } from './CompanyForm';
import { CreateCompanySchema } from './CompanyFormSchema';
import { FormProps } from '../common/react-admin/interfaces';

const CompanyCreateForm = (raProps: FormProps) => {
  const company = { ...raProps.record, validatePhone: true };

  const onSubmit = async (
    values: CompanyFormValues,
    actions: FormikActions<CompanyFormValues>
  ) => {
    const data = {
      contactManagerId: values.contactManagerId,
      name: values.name,
      type: values.type,
      activeFrom: values.isScheduled ? values.activeFrom : null,
      activeTo: values.isScheduled ? values.activeTo : null,
      user: {
        username: values.username,
        ...(values.password ? { password: values.password } : {}),
        roles: [UserRole.COMPANY],
      },
      driver: {
        familyName: values.familyName,
        firstName: values.firstName,
        privatePhone: values.privatePhone,
        publicPhone: values.publicPhone,
      },
      companyAddressZip: values.zip || null,
      companyAddressCity: values.place || null,
      companyAddressStreet: values.street || null,
      companyAddressNumber: values.houseNumber || null,
      latitude: values.latitude || null,
      longitude: values.longitude || null,
    };
    try {
      await dataProvider(CREATE, raProps.resource, { data });
      actions.setSubmitting(false);
      raProps.history.push(getApiRoutes(false, '').companyAdmin.list);
    } catch (error) {
      raProps.showNotification(__(error?.toString() ?? 'Error'), 'warning');
      console.log(error);
      actions.setSubmitting(false);
    }
  };

  return (
    <Formik
      initialValues={company}
      validationSchema={CreateCompanySchema}
      onSubmit={onSubmit}
    >
      <CompanyForm
        companyTypes={newCompanyTypes}
        onCancel={() =>
          raProps.history.push(getApiRoutes(false, '').companyAdmin.list)
        }
      />
    </Formik>
  );
};

const mapCreateStateToProps = (state: any) => ({
  resources: getResources(state),
});

const ConnectedCompanyCreateForm = connect(mapCreateStateToProps, {
  showNotification: showNotificationAction,
})(CompanyCreateForm);

export default (raProps: any) => (
  <Create {...raProps}>
    <ConnectedCompanyCreateForm {...raProps} />
  </Create>
);
