export var ComplexityTypes;
(function (ComplexityTypes) {
    ComplexityTypes["CLOSED"] = "closed";
    ComplexityTypes["LOCKED"] = "locked";
    ComplexityTypes["INFO"] = "info";
    ComplexityTypes["FAILURE"] = "failure";
})(ComplexityTypes = ComplexityTypes || (ComplexityTypes = {}));
export var ProductTypes;
(function (ProductTypes) {
    ProductTypes["PREMIUM"] = "premium";
    ProductTypes["STANDARD"] = "standard";
    ProductTypes["INSURANCE"] = "insurance";
})(ProductTypes = ProductTypes || (ProductTypes = {}));
export var InfoCaseTypes;
(function (InfoCaseTypes) {
    InfoCaseTypes["CAR_OPENING"] = "carOpening";
    InfoCaseTypes["WANTED_DIFFERENT_SERVICE"] = "wantedDifferentService";
    InfoCaseTypes["PRICE_TOO_HIGH"] = "priceTooHigh";
    InfoCaseTypes["CUSTOMER_HUNG_UP"] = "customerHungUp";
    InfoCaseTypes["WRONG_TYPE_OF_DOOR"] = "wrongTypeOfDoor";
    InfoCaseTypes["ZIP_NOT_SUPPORTED"] = "zipNotSupported";
    InfoCaseTypes["OTHER"] = "other";
})(InfoCaseTypes = InfoCaseTypes || (InfoCaseTypes = {}));
