import { SET_CLOSING_CASE, CaseCloseType } from '../actions/setClosingCase';

// eslint-disable-next-line default-param-last
export default (
  previousState = null,
  { type, payload }: { type: string; payload: CaseCloseType }
) => {
  if (type === SET_CLOSING_CASE) {
    return payload;
  }
  return previousState;
};
