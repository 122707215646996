import { BooleanInput } from 'react-admin';
import { __ } from '@adac/core-model';
import ClosedCaseFilter from './OpenCaseFilter';

export * from './OpenCaseFilter';

export default (props: any) => (
  <ClosedCaseFilter {...props}>
    <BooleanInput label={__('Case has invoice')} source='hasInvoice' />
  </ClosedCaseFilter>
);
