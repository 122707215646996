import { ImageReport } from '@adac/core-model';
import { useCallback, useEffect, useState } from 'react';
import { EditReportImageFormValues } from '../components/cases/caseStatus/EditReportModal';
import { getReportImages, updateReportImages } from '../networking/report';

export function useImageReports(caseToken?: string) {
  const [reports, setReports] = useState<ImageReport | undefined>(undefined);

  const fetchImages = useCallback(async () => {
    if (!caseToken) {
      console.warn('Invalid token for user/case');
      return;
    }
    const response = await getReportImages(caseToken);
    setReports(response);
  }, [caseToken]);

  useEffect(() => {
    fetchImages();
  }, [fetchImages]);

  function updateReports(updatedValues: EditReportImageFormValues) {
    if (!caseToken) {
      console.warn('Invalid token for user/case');
      return;
    }
    updateReportImages(caseToken, updatedValues);
  }

  return {
    reports,
    setReports,
    updateReports,
  };
}
