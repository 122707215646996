export const getLang = (language, _currentLang) => (language || _currentLang || '').toLowerCase();
const findEntry = (key, localization) => Object.entries(localization).find((item) => item[0].toLowerCase() === key.toLowerCase());
const flattenArray = (object) => object && ((Array.isArray(object) && object.join('\n')) || String(object));
export const selectObject = (found, language, defaultLang) => (found && found[getLang(language)]) ||
    (found && defaultLang && found[defaultLang]);
function getStringFromLocalization(title, language, myLocalization) {
    if (!myLocalization)
        return '';
    if (typeof title !== 'string')
        return title;
    const key = (title.indexOf('\n') && title.split('\n') && title.split('\n')[0].trim()) ||
        title;
    const defaultLang = myLocalization.keys[0];
    const a = findEntry(key, myLocalization);
    const found = myLocalization[key] || (a && a[1]);
    return (!Array.isArray(found) &&
        selectObject(found, language, defaultLang) &&
        flattenArray(selectObject(found, language, defaultLang)));
}
export function i18n(title, language, myLocalization) {
    const retString = getStringFromLocalization(title, language, myLocalization);
    return retString || title;
}
export function nullableI18n(title, language, myLocalization) {
    const retString = getStringFromLocalization(title, language, myLocalization);
    return retString || null;
}
