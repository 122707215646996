import {
  __,
  allowedUserSignInSettingRegexp,
  AuthenticationMode,
  BackofficeUser,
  AvailableNotificationSettingValues as Channel,
  isValidMobilePhoneNumber,
  sanitizePhoneNumber,
} from '@adac/core-model';
import {
  Button,
  ButtonTitle,
  ColumnLayout,
  ErrorMessage,
  FlexView,
  LoadingSpinner,
  NetworkLoader,
  SubTitle,
  Text,
  TextField,
} from '@adac/core-view';
import { MenuItem, Select } from '@material-ui/core';
import {
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
  useFormikContext,
} from 'formik';
import React, {
  useCallback,
  useContext,
  useLayoutEffect,
  useState,
} from 'react';
import styled from 'styled-components';
import * as yup from 'yup';
import { useMe } from '../../hooks/useMe';
import { getSignInUserSetting } from '../../networking/settings';
import StoresContext from '../../stores';
import { FormProps } from '../common/react-admin/interfaces';
import CardContent from '../snd/CardContent';
import { LayoutStyles } from './Profile';

export interface SignInChannelFormValues {
  channel: Channel;
  address: string;
}

export const ChannelFormValues = yup.object({
  channel: yup
    .string()
    .matches(allowedUserSignInSettingRegexp, 'Invalid channel format')
    .required('Channel is required'),
});

export const SignInChannelSchema = ChannelFormValues.concat(
  yup.object({
    address: yup.string().when('channel', {
      is: (channel: Channel) => channel === Channel.SMS,
      then: (schema) =>
        schema.test('validPhone', 'phone has to start with plus', (phone) =>
          isValidMobilePhoneNumber(phone)
        ),
      otherwise: (schema) => schema.notRequired(),
    }),
  })
);

interface SignInChannelFormProps extends FormProps {
  onSubmit: (
    values: SignInChannelFormValues,
    actions: FormikHelpers<SignInChannelFormValues>
  ) => void;
}

export function UserSignInChannelForm({
  onSubmit,
  ...raProps
}: SignInChannelFormProps) {
  const {
    auth: { token },
  } = useContext(StoresContext);
  const { user, isLoading } = useMe();

  const initialValues = {
    channel: Channel.SMS,
    address: user?.phone || '',
  };

  return (
    <LayoutStyles>
      <NetworkLoader
        loading={isLoading}
        loadingView={<LoadingSpinner>{__('Loading')}</LoadingSpinner>}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={SignInChannelSchema}
          onSubmit={onSubmit}
        >
          {({
            isSubmitting,
            isValid,
          }: FormikProps<SignInChannelFormValues>) => (
            <Form>
              <SubTitle>{__('Login')}</SubTitle>

              {user && token && <ChannelForm token={token} user={user} />}

              <CardContent>
                <fieldset>
                  <Button
                    info
                    type='button'
                    onClick={() => raProps.history.goBack()}
                  >
                    <ButtonTitle>{__('Cancel')}</ButtonTitle>
                  </Button>
                  <Button
                    cta
                    type='submit'
                    isLoading={isSubmitting}
                    disabled={isSubmitting || !isValid}
                    title={__('Save')}
                  />
                </fieldset>
              </CardContent>
            </Form>
          )}
        </Formik>
      </NetworkLoader>
    </LayoutStyles>
  );
}

interface ChannelFormProps {
  token: string | null;
  user?: BackofficeUser;
}

export const ChannelForm = ({ token, user }: ChannelFormProps) => {
  const { setFieldValue } = useFormikContext<SignInChannelFormValues>();
  const [isLoading, setIsLoading] = useState(true);

  const fetchSignInChannel = useCallback(async () => {
    if (!token) {
      console.error('token not found for user');
      setIsLoading(false);
      return;
    }

    if (!user || !user.id) {
      console.error('user id not found');
      setIsLoading(false);
      return;
    }

    try {
      const settings = await getSignInUserSetting(user.id);
      const channel =
        settings[AuthenticationMode.SIGN_IN_CHANNEL] || Channel.SMS;
      const address = channel === Channel.SMS ? user.phone : user.username;

      setFieldValue('channel', channel);
      setFieldValue('address', address);
    } catch (error) {
      console.error(error);
    } finally {
      setIsLoading(false);
    }
  }, [token, user, setIsLoading, setFieldValue]);

  useLayoutEffect(() => {
    fetchSignInChannel();
  }, [fetchSignInChannel]);

  return (
    <NetworkLoader
      loading={isLoading}
      loadingView={<LoadingSpinner>{__('Loading')}</LoadingSpinner>}
    >
      <FlexView column>
        <ChannelOptionFields phone={user?.phone} email={user?.username} />
        <ChannelValueFields />
      </FlexView>
    </NetworkLoader>
  );
};

export type ChangeEvent = React.ChangeEvent<
  | HTMLInputElement
  | HTMLTextAreaElement
  | HTMLSelectElement
  | { name?: string; value: unknown }
>;

interface ChannelOptionFieldsProps {
  phone?: string;
  email?: string;
  onChange?: (e: ChangeEvent) => void;
}

export function ChannelOptionFields({
  onChange,
  phone,
  email,
}: ChannelOptionFieldsProps) {
  const {
    values: { channel },
    setFieldValue,
    validateForm,
  } = useFormikContext<SignInChannelFormValues>();

  function onChannelChange(e: ChangeEvent) {
    const newAddress = e.target.value === Channel.SMS ? phone : email;

    setFieldValue('channel', e.target.value);
    setFieldValue('address', newAddress);

    /**
     * The form needs to be re-validated, especially after setting the channel to email.
     * Otherwise, the confirm button remains disabled until the channel select field loses focus.
     */
    validateForm();
  }

  const onChangeFn = onChange || onChannelChange;

  return (
    <ColumnLayout ratio='2fr 1fr' center gap='10px' marginTop='18px'>
      <Text>
        {__(
          'Method of sending the verification code for two-factor authentication'
        )}
        :
      </Text>
      <Field
        name='channel'
        render={({ field }: FieldProps<'channel', SignInChannelFormValues>) => (
          <Select {...field} onChange={(e) => onChangeFn(e)}>
            {[Channel.SMS, Channel.EMAIL].map((ch) => (
              <MenuItem selected={channel === ch} value={ch}>
                {__(`mfa:${ch}`)}
              </MenuItem>
            ))}
          </Select>
        )}
      />
    </ColumnLayout>
  );
}

const UserNameText = styled.span`
  font-weight: bold;
`;

export function ChannelValueFields() {
  const {
    values: { channel, address },
    errors,
    setFieldValue,
  } = useFormikContext<SignInChannelFormValues>();
  const isSmsChannel = channel === Channel.SMS;

  function onAddressChange(e: React.ChangeEvent<HTMLInputElement>) {
    const value =
      channel === Channel.SMS
        ? sanitizePhoneNumber(e.target.value)
        : e.target.value;

    setFieldValue('address', value);
  }

  return (
    <ColumnLayout
      ratio={isSmsChannel ? '2fr 1fr' : '1fr'}
      center
      gap='10px'
      marginTop='18px'
    >
      {isSmsChannel ? (
        <Text>
          {__(
            'SMS-capable mobile phone number for sending the verification code'
          )}
          :
        </Text>
      ) : (
        <Text>
          {__(
            'Email address entered in the Username field will be used to send the verification code'
          )}
          :<UserNameText> {address}</UserNameText>
        </Text>
      )}

      {isSmsChannel && (
        <>
          <Field
            name='address'
            render={({
              field,
            }: FieldProps<'address', SignInChannelFormValues>) => (
              <TextField
                {...field}
                onChange={(e) => onAddressChange(e)}
                badgeTitle={__(`mfa:${channel}`)}
                badgeEqualsPlaceholder
              />
            )}
          />
          {errors.address && <ErrorMessage>{__(errors.address)}</ErrorMessage>}
        </>
      )}

      {errors.channel && <ErrorMessage>{__(errors.channel)}</ErrorMessage>}
    </ColumnLayout>
  );
}
