const getConfig = () => {
    const { GEOCODE_SERVICE_BASE_URL: baseUrl = 'https://geocode.search.hereapi.com/v1', GEOCODE_SERVICE_API_KEY: apiKey, GEOCODE_SERVICE_MIN_CONFIDENCE_DISPLAY: minConfidence, GEOCODE_SERVICE_SUGGESTION_LIMIT: suggestionLimit, GEOCODE_SERVICE_MIN_CONFIDENCE_ACCEPT_DOOR: acceptDoorConfidence, GEOCODE_SERVICE_MIN_CONFIDENCE_ACCEPT_BILLING: acceptBilingConfidence, GEOCODE_SERVICE_TIMEOUT: configuredTimeout, GEOCODE_SERVICE_TEST_ZIP: testZipCode, REACT_APP_GEOCODE_SERVICE_MAX_AGE: browserMaxAge, REACT_APP_GEOCODE_SERVICE_TIMEOUT: browserTimeout, REACT_APP_GEOCODE_SERVICE_ENABLE_HIGH_ACCURACY: browserEnableHighAccuracy, REACT_APP_ADDRESSFORM_MINLEN_ZIP_BILLING: minBillingZipLen, REACT_APP_ADDRESSFORM_MINLEN_PLACE: minPlaceLen, REACT_APP_ADDRESSFORM_MINLEN_STREET: minStreetLen, } = process.env;
    return {
        baseUrl,
        apiKey,
        apiTimeout: Number(configuredTimeout) || 3000,
        browserTimeout: Number(browserTimeout) || 5000,
        browserMaxAge: Number(browserMaxAge) || 0,
        enableHighAccuracy: Boolean(browserEnableHighAccuracy) || true,
        minConfidence: Number(minConfidence) || 0.4,
        suggestionLimit: Number(suggestionLimit) || 4,
        acceptDoorConfidence: Number(acceptDoorConfidence) || 0.96,
        acceptBilingConfidence: Number(acceptBilingConfidence) || 0.8,
        testZipCode: Number(testZipCode) || 99999,
        minZipLen: Number(minBillingZipLen) || 4,
        minPlaceLen: Number(minPlaceLen) || 4,
        minStreetLen: Number(minStreetLen) || 3,
        retryAttempts: 3,
    };
};
export const getLocationAPIRoutes = (validateConfig = true) => {
    const { apiKey, baseUrl } = getConfig();
    if (validateConfig && !(baseUrl && apiKey))
        throw new Error('GEOCODE_SERVICE_API_KEY has to be defined!');
    return Object.freeze({
        retrieve: {
            address: ({ latitude, longitude }) => `${baseUrl}/revgeocode?at=${encodeURI(`${latitude},${longitude}`)}&apiKey=${apiKey}`,
            coords: ({ zip, place, street, houseNumber }) => `${baseUrl}/geocode?q=${encodeURI(`${zip},${place},${street},${houseNumber}`)}&apiKey=${apiKey}`,
            suggestions: ({ zip, place, street, houseNumber }) => `${baseUrl}/geocode?q=${encodeURI(`${zip},${place},${street},${houseNumber}`)}&apiKey=${apiKey}`,
        },
    });
};
export default getConfig;
