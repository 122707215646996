import { useContext } from 'react';
import { /* styled, */ ThemeProvider } from 'styled-components';
import { observer } from 'mobx-react';

import {
  ProtectedSocketClientProvider,
  RouterContextContainer,
} from '@adac/core-view';

import StoresContext from './stores';

import Admin from './components/admin/Admin';
import { useTokenListener } from './helpers/useTokenListener';
import LoginRouterContainer from './components/admin/LoginRouterContainer';

const App = observer(() => {
  const {
    auth: { token, isLoggedIn },
    ui: { currentTheme },
  } = useContext(StoresContext);

  useTokenListener();

  return (
    <ProtectedSocketClientProvider token={token}>
      <ThemeProvider theme={currentTheme}>
        {isLoggedIn ? (
          <Admin />
        ) : (
          <RouterContextContainer>
            <LoginRouterContainer />
          </RouterContextContainer>
        )}
      </ThemeProvider>
    </ProtectedSocketClientProvider>
  );
});

export default App;
