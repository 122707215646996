import { User } from '@adac/core-model';
import { useCallback } from 'react';
import { USERS_BLOCKED } from '../dataProvider/resources';
import { getBlockedList } from '../networking/unblock';

export const setBlockedUsersAction = (blockedUserList: User[]) => ({
  type: USERS_BLOCKED,
  payload: blockedUserList,
});

type SetActionType = (users: User[]) => void;

/**
 * Use this hook whenever you need to refetch and store blocked users.
 * Pair it with the setBlockedUsersAction function, which works in conjunction with the blockedUsersReducer.
 */
export function useBlockedUsersAction(setCallback: SetActionType) {
  const get = useCallback(async () => {
    const users = await getBlockedList();
    setCallback(users);
  }, [setCallback]);

  return { get };
}
