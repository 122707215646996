import {
  getApiRoutes,
  CompanyTypes,
  CustomerDataWithSms,
  __,
} from '@adac/core-model';
import { stores } from '../stores';
import {
  OPEN_CASES,
  NEW_CASE,
  SND_COMPANIES,
  SND_COMPANY_DRIVERS,
  DRIVERS,
  EDIT_CASE,
  ADMIN_LOGS,
  USERS_BLOCKED,
} from './resources';

import { mapCustomerDataToBackend } from '../helpers/customerData';

const apiUrl = getApiRoutes().root;

export const getHeader = () => ({
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: `Bearer ${stores.auth.token}`,
});

export const getOptions = (): any => ({
  // eslint-disable-next-line no-undef
  headers: new Headers(getHeader()),
});

const mapActionFilter = (action: any) =>
  __(`filter:${String(action).toLowerCase()}`);

const substringToAction = (substring: any) => {
  const translatedUpdateString = __('httpAction:PUT').toLowerCase();
  const translatedPostString = __('httpAction:POST').toLowerCase();
  const translatedDeleteString = __('httpAction:DELETE').toLowerCase();
  const translatedGetString = __('httpAction:GET').toLowerCase();

  if (translatedUpdateString.includes(substring.toLowerCase()))
    return translatedUpdateString;
  if (translatedPostString.includes(substring.toLowerCase()))
    return translatedPostString;
  if (translatedDeleteString.includes(substring.toLowerCase()))
    return translatedDeleteString;
  if (translatedGetString.includes(substring.toLowerCase()))
    return translatedGetString;

  return substring;
};

const createCaseUrl = (baseString: string, params: any, resource?: any) => {
  // console.log('PARAMS: ', params);
  const { sort, filter } = params;
  const concatedFilterStrings =
    filter &&
    Object.entries(filter)
      .map((entry) => {
        const [key, value] = entry;
        switch (key) {
          case 'companyTypeSelect':
            return `type=${JSON.stringify({
              eq: value,
            })}`;
          case 'onlySND':
            return `type=${JSON.stringify({
              in: value ? [CompanyTypes.EXT] : Object.values(CompanyTypes),
            })}`;
          case 'active':
          case 'id':
          case 'driverId':
          case 'companyId':
          case 'year':
          case 'futureHoliday':
          case 'hasInvoice':
          case 'entityId': // AdminLog
          case 'userId': // AdminLog
            return `${key}=${JSON.stringify({ eq: value })}`;
          case 'createdAtFrom': // AdminLog
            if (!filter.createdAtTo)
              return `${key.replace(/(From|To)$/, '')}=${JSON.stringify({ gte: value })}`;
            return `${key.replace(/(From|To)$/, '')}=${JSON.stringify({ between: [`${value} 00:00:00`, `${filter.createdAtTo} 23:59:59`] })}`;
          case 'createdAtTo': // AdminLog
            if (!filter.createdAtFrom)
              return `${key.replace(/(From|To)$/, '')}=${JSON.stringify({ lte: value })}`;
            return null;
          // case 'zipCode': // zip:TODO2: check
          case 'token':
          case 'customerFamilyName':
          case 'customerFirstName':
          case 'customerAddressCity':
          case 'customerAddressStreet':
          case 'customerPhone':
          case 'customerAddressZip':
          case 'productType':
          case 'description':
          case 'username':
          case 'name':
          case 'contactManagerId':
          case 'status':
          case 'role':
          case 'companyName':
          case 'dispatcherName':
          case 'privatePhoneUrl':
          case 'commissioningReference':
          case 'data': // AdminLog
          case 'entity': // AdminLog
          case 'response': // AdminLog
          case 'url': // AdminLog
            return `${key}=${JSON.stringify({ like: value })}`;
          case 'action':
            return resource === ADMIN_LOGS
              ? `${key}=${JSON.stringify({ like: mapActionFilter(substringToAction(value)) })}`
              : `${key}=${JSON.stringify({ like: value })}`;
          default:
            // console.warn('not using unknown filter:', key, '=', value);
            return null;
        }
      })
      .join('&');

  const getSortString = (options: { field: string; order: string }): string => {
    if (!options) return '';
    switch (options.field) {
      case 'price':
        return `&sort=[{"field": "totalAmount", "order": "${options.order}"}]`;
      case 'timeOfOrder':
        return `&sort=[{"field": "createdAt", "order": "${options.order}"}]`;
      case 'customerZipCode':
        return `&sort=[{"field": "customerAddressZip", "order": "${options.order}"}]`;
      case 'customerAddress':
        return `&sort=[{"field": "customerAddressZip", "order": "${options.order}"}, {"field": "customerAddressCity", "order": "${options.order}"}, {"field": "customerAddressStreet", "order": "${options.order}"}, {"field": "customerAddressNumber", "order": "${options.order}"}]`;
      case 'customerName':
        return `&sort=[{"field": "customerFirstName", "order": "${options.order}"}, {"field": "customerFamilyName", "order": "${options.order}"}]`;
      case 'typeName':
        return `&sort=[{"field": "type", "order": "${options.order}"}]`;
      default:
        return `&sort=[{"field": "${options.field}", "order": "${options.order}"}]`;
    }
  };

  const sortString = getSortString(sort);
  const filterString = concatedFilterStrings ? `&${concatedFilterStrings}` : '';

  // console.log('FITLER: ', filterString);
  // console.log('REQUEST: ', `${baseString}&${sortString}&${filterString}`);

  return `${baseString}${sortString}${filterString}`;
};

export const createRequestForGetList = (resource: string, params: any) => {
  const { page, perPage } = params.pagination;

  const paginationString = `page=${page - 1}&perPage=${perPage}`;

  const defaultResource = `${apiUrl}/${resource}`;

  const url = (() => {
    // TODO: refactor this out for params, received from UI
    switch (resource) {
      // case SND_COMPANIES:
      //   // if we have a taken case during escalation, we should filter only for not-inactive companies, but no more filter, neither paging allowed!
      //   if (params.filter.takenCase) return `${defaultResource}?${createCaseUrl('', { filter: { typeName: true } })}`;
      //   break;
      case USERS_BLOCKED: {
        return `${getApiRoutes(false, apiUrl).backoffice.users.listBlocked}?${createCaseUrl(paginationString, { ...params })}`;
      }
      case DRIVERS:
      case SND_COMPANY_DRIVERS:
        return `${apiUrl}/${DRIVERS}?${createCaseUrl(paginationString, { ...params })}`;
      default:
        break;
    }

    return `${defaultResource}?${createCaseUrl(paginationString, params, resource)}`;
  })();

  return { url, options: getOptions() };
};

export const createRequestForGetOne = (resource: string, params: any) => {
  let url = `${apiUrl}/${resource}/${params.id}`;
  switch (resource) {
    case SND_COMPANIES:
      url = getApiRoutes().companyAdmin.getById(params.id);
      break;
    case DRIVERS:
    case SND_COMPANY_DRIVERS:
      url = `${apiUrl}/${DRIVERS}/${params.id}`;
      break;
    default:
      break;
  }
  return { url, options: getOptions() };
};

const mapCaseFormValuesToBackend = (data: CustomerDataWithSms) =>
  JSON.stringify(mapCustomerDataToBackend(data));

export const createRequestForCreate = (resource: string, params: any) => {
  const { data } = params;

  let url = `${apiUrl}/${resource}`;
  let body = JSON.stringify(data);

  switch (resource) {
    case NEW_CASE:
      url = getApiRoutes().backoffice.case.create;
      body = mapCaseFormValuesToBackend(data);
      break;
    case OPEN_CASES:
      url = getApiRoutes().backoffice.case.list.open;
      body = mapCaseFormValuesToBackend(data);
      break;
    case SND_COMPANIES:
      body = JSON.stringify({
        ...data,
        user: {
          ...data.user,
          name: `${data.driver.firstName} ${data.driver.familyName}`,
        },
      });
      break;
    case DRIVERS:
    case SND_COMPANY_DRIVERS:
      url = `${apiUrl}/${DRIVERS}`;
      break;
    default:
      break;
  }

  const newOptions = { ...getOptions() };
  newOptions.method = 'POST';
  newOptions.body = body;

  return { url, options: newOptions };
};

export const createRequestForUpdate = (resource: string, params: any) => {
  let url = `${apiUrl}/${resource}/${params.id}`;

  const newOptions = { ...getOptions() };
  newOptions.method = 'PUT';

  switch (resource) {
    case SND_COMPANIES:
      // eslint-disable-next-line no-case-declarations
      const { driver, user, ...companyData } = params.data;
      newOptions.body = JSON.stringify({
        ...companyData,
        driver: {
          ...driver,
          user: {
            ...user,
            name: `${driver?.firstName} ${driver?.familyName}`,
          },
        },
      });
      break;
    case EDIT_CASE:
      throw new Error('EDIT_CASE feature terminated');
    case SND_COMPANY_DRIVERS:
    case DRIVERS:
      url = `${apiUrl}/${DRIVERS}/${params.id}`;
      newOptions.body = JSON.stringify({
        id: params.id,
        companyId: params.companyId,
        familyName: params.familyName,
        firstName: params.firstName,
        privatePhone: params.privatePhone,
        publicPhone: params.publicPhone,
        validatePhone: params.validatePhone,
        photo: params.photo,
        user: params.user,
        companyAddressZip: params.companyAddressZip,
        companyAddressCity: params.companyAddressCity,
        companyAddressStreet: params.companyAddressStreet,
        companyAddressNumber: params.companyAddressNumber,
        latitude: params.latitude,
        longitude: params.longitude,
      });
      break;
    default:
      newOptions.body = JSON.stringify(params.data);
      break;
  }
  return { url, options: newOptions };
};

export const createRequestForDelete = (resource: string, params: any) => {
  let url = `${apiUrl}/${resource}/${params.id}`;
  switch (resource) {
    case SND_COMPANY_DRIVERS:
      url = `${apiUrl}/${DRIVERS}/${params.id}`;
      break;
    default:
      break;
  }
  const newOptions = { ...getOptions() };
  newOptions.method = 'DELETE';
  return { url, options: newOptions };
};

// Example of query params (Get list):
// const { page, perPage } = params.pagination;
// const { field, order } = params.sort;
// const query = {
//     sort: JSON.stringify([field, order]),
//     range: JSON.stringify([
//         (page - 1) * perPage,
//         page * perPage - 1,
//     ]),
//     filter: JSON.stringify(params.filter),
// };
// url = `${apiUrl}/${resource}?${stringify(query)}`;
