/* eslint-disable import/no-extraneous-dependencies */
import {
  showNotification as showNotificationAction,
  getResources,
  Show,
} from 'react-admin';
import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Text, themeConfig, GeneralButton } from '@adac/core-view';
import { __, AdminLogDataReport, AdminLogRecord } from '@adac/core-model';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import moment from 'moment';
import CustomCard from '../common/CustomCard';
import CaseStatusHeader from '../cases/caseStatusComponents/CaseStatusHeader';
import { getCommissioningReferenceFromAdminLogData } from '../../dataProvider/responseHandlers';
import { RARecordProps } from '../common/react-admin/interfaces';

const Card = styled(CustomCard)`
  display: flex;
  flex-direction: column;
  place-items: initial;
`;

const LogDetailsContainer = styled.div`
  display: flex;
  justify-content: start;
  align-items: center;
  padding: 32px;
`;

const LogDetailsGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, max-content);
  grid-template-rows: repeat(3, max-content);
  gap: 16px;
`;
interface GridItemProps {
  flexDirection?: string;
}

const GridItem = styled.div<GridItemProps>`
  display: flex;
  gap: 8px;
  align-items: ${(props) =>
    props.flexDirection === 'row' ? 'center' : 'left'};
  flex-direction: ${(props) => props.flexDirection || 'row'};
  font-size: 16px;
`;

const Label = styled.div`
  font-weight: bold;
`;

const Value = styled.p`
  white-space: pre-wrap;
`;

const StyledStatus = styled(Value)<{ status: number }>`
  border-radius: 8px;
  padding-left: 6px;
  padding-right: 6px;
  background-color: ${(props) => props.status && (props.status < 200 || props.status > 299 ? themeConfig.colors.warning : themeConfig.colors.progress)};
  color: ${themeConfig.colors.background} !important;
  }};
`;

const removeProperty = (
  obj: AdminLogDataReport | null | undefined,
  prop: keyof AdminLogDataReport
) => {
  if (!obj || !(prop in obj)) {
    return obj;
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const { [prop]: _, ...res } = obj;
  return res;
};

const AdminLogDetails = ({
  record: logDetails,
}: RARecordProps<AdminLogRecord>) => {
  const displayChanged = logDetails.entity !== 'Case';
  const [showMore, setShowMore] = React.useState(!displayChanged);

  return (
    <Card>
      <CaseStatusHeader>
        <Text bold>Admin log - {logDetails.id}</Text>
      </CaseStatusHeader>
      <LogDetailsContainer>
        <LogDetailsGrid>
          <GridItem>
            <Label>{__('CommissioningReference')}:</Label>
            <Value>
              {getCommissioningReferenceFromAdminLogData(logDetails.data) ||
                '-'}
            </Value>
          </GridItem>
          <GridItem>
            <Label>{__('Origin')}:</Label>
            <Value>{logDetails.origin}</Value>
          </GridItem>
          <GridItem>
            <Label>{__('Entity')}:</Label>
            <Value>
              {logDetails.entity} (#{logDetails.entityId})
            </Value>
          </GridItem>
          <GridItem>
            <Label>{__('URL')}:</Label>
            <Value>{logDetails.url}</Value>
          </GridItem>
          <GridItem>
            <Label>{__('Action')}:</Label>
            <Value>{logDetails.action}</Value>
          </GridItem>
          <GridItem>
            <Label>{__('createdAt')}:</Label>
            <Value>
              {moment(logDetails.createdAt).format('DD-MM-YY HH:mm')}
            </Value>
          </GridItem>
          <GridItem>
            <Label>{__('Status')}</Label>
            <StyledStatus status={logDetails.status}>
              {logDetails.status}
            </StyledStatus>
          </GridItem>
          <GridItem>
            <Label>{__('User ID')}:</Label>
            <Value>
              {logDetails.userId}
              {logDetails.user?.name && `(${logDetails.user?.name})`}
            </Value>
          </GridItem>
          {displayChanged && (
            <GridItem flexDirection='column'>
              <Label>{__('Changed')}:</Label>
              <Value>
                {JSON.stringify(logDetails.data?.changed, null, 2) || 'NULL'}
              </Value>
            </GridItem>
          )}
          {displayChanged && (
            <GridItem flexDirection='column'>
              {!showMore && (
                <GeneralButton
                  title='More info'
                  onClick={() => setShowMore(true)}
                >
                  <ArrowDropDownIcon color='primary' />
                </GeneralButton>
              )}
            </GridItem>
          )}
          {showMore && (
            <GridItem flexDirection='column'>
              <Label>{__('Request Data')}:</Label>
              <Value>
                {JSON.stringify(
                  removeProperty(logDetails.data, 'changed'),
                  null,
                  2
                )}
              </Value>
            </GridItem>
          )}
          {showMore && (
            <GridItem flexDirection='column'>
              <Label>{__('Response Data')}:</Label>
              <Value>
                {JSON.stringify(logDetails.response, null, 2) || 'NULL'}
              </Value>
            </GridItem>
          )}
        </LogDetailsGrid>
      </LogDetailsContainer>
    </Card>
  );
};

const mapEditStateToProps = (state: any) => ({
  resources: getResources(state),
});

export const ConnectedAdminLogDetails = connect(mapEditStateToProps, {
  showNotification: showNotificationAction,
})(AdminLogDetails);

export default (raProps: RARecordProps<AdminLogRecord>) => (
  <Show {...raProps}>
    <ConnectedAdminLogDetails {...raProps} />
  </Show>
);
