import styled from 'styled-components';

import { Card } from '@material-ui/core';

import { View, Text, withLink, ChildrenProps } from '@adac/core-view';

interface StyleProps {
  rowHeight: number;
}

const Styles = styled(Card)<StyleProps>`
  width: max-content;
  padding: 16px;

  position: absolute;
  right: 0;
  top: ${(props) => props.rowHeight - 12}px;
  z-index: 100;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const ItemStyles = styled(View)`
  display: flex;
  justify-content: flex-start;

  > *:first-child {
    margin-right: 4px;
  }

  :not(:last-child) {
    margin-bottom: 16px;
  }
`;

const ItemTitle = styled(Text)`
  font-size: 1.6rem;
`;

export interface Item extends JSX.IntrinsicAttributes {
  title: string;
  icon: JSX.Element;
  fileDownloadLink?: string;
  onClick?: () => void;
  visible?: boolean;
}

export interface ListActionPopupProps extends StyleProps, ChildrenProps {
  items: Item[];
}

export const ListItemComponent = (item: Item) => (
  <ItemStyles>
    {item.icon}
    <ItemTitle>{item.title}</ItemTitle>
  </ItemStyles>
);

let Component = (itemProps: Item) => <ListItemComponent {...itemProps} />;

export const ListActionItemComponent = (item: Item) => {
  if (item.fileDownloadLink) {
    Component = withLink(ListItemComponent, {
      fileDownloadLink: item.fileDownloadLink,
    });
  }

  if (item.onClick) {
    Component = withLink(ListItemComponent, { onClick: item.onClick });
  }

  return <Component {...item} />;
};

const ListActionPopup = ({
  items,
  rowHeight,
  children,
}: ListActionPopupProps) => {
  const visibleItems = items.filter((item) => item.visible);
  if (visibleItems.length === 0) return null;
  return (
    <Styles rowHeight={rowHeight}>
      {visibleItems.map((item) => (
        <ListActionItemComponent key={item.title} {...item} />
      ))}
      {children}
    </Styles>
  );
};

export default ListActionPopup;
