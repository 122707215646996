import { loadFromLocalStorage } from '@adac/core-model';
import { AuthStore } from '@adac/core-view';
import { useContext, useEffect } from 'react';
import StoresContext from '../stores';

export function useTokenListener() {
  const {
    auth: { deleteToken },
  } = useContext(StoresContext);

  useEffect(() => {
    function logoutUnauthorizedUser() {
      const token = loadFromLocalStorage(AuthStore.TOKEN_KEY);
      if (!token) {
        deleteToken();
      }
    }

    logoutUnauthorizedUser();

    // Check & Log out user on storage change
    window.addEventListener('storage', () => logoutUnauthorizedUser());

    return () => {
      window.removeEventListener('storage', () => logoutUnauthorizedUser());
    };
  }, [deleteToken]);
}
