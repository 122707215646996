export function formatNumber(val, decimal = 2, decimalPoint = ',', thousandsSeparator = '.') {
    if (Number.isNaN(val))
        return val;
    const n = val;
    const c = Number.isNaN(Math.abs(decimal)) ? 2 : decimal;
    const d = decimalPoint === undefined ? '.' : decimalPoint;
    const t = thousandsSeparator === undefined ? ',' : thousandsSeparator;
    const s = n < 0 ? '-' : '';
    const sign = n < 0 ? -1 : 1;
    const i = String(parseInt(Math.abs(Number(n) || 0).toFixed(c), 10));
    const j = i.length > 3 ? i.length % 3 : 0;
    const decimals = Math.abs(n - sign * Number(i))
        .toFixed(c)
        .slice(2);
    return (s +
        (j ? i.substr(0, j) + t : '') +
        i.substr(j).replace(/(\d{3})(?=\d)/g, `$1${t}`) +
        (c ? d + decimals : ''));
}
export function getNumber(numberinput, defaultNumber = -1) {
    const number = `${numberinput}`
        .replace(/[,]/g, '.')
        .replace(/[ ]+/g, '')
        .replace(/^([+-])?[0]+/, '0');
    try {
        if (!Number.isNaN(number)) {
            return parseFloat(number);
        }
    }
    catch (e) {
        console.warn('getNumber error', numberinput, number, Number.isNaN(number), e);
    }
    return defaultNumber;
}
export const getRandomInt = (min, max) => {
    const minRounded = Math.ceil(min);
    const maxRounded = Math.floor(max);
    return Math.floor(Math.random() * (maxRounded - minRounded)) + minRounded;
};
