import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#191919',
      light: '#fff',
      dark: '#191919',
      contrastText: '#191919',
    },
    secondary: {
      main: '#ffcc00',
      light: '#eee',
      dark: '#191919',
      contrastText: '#191919',
    },
  },
  typography: {
    htmlFontSize: 10,
    // fontSize: 16,
    fontFamily: ['MiloADACOffc', 'sans-serif'].join(','),
  },
  overrides: {
    MuiAppBar: {
      root: {
        boxShadow: 'none',
        // minHeight: 48,
        // height: 48,
      },
    },
    MuiTableCell: {
      root: {
        padding: '0px 3px',
        textAlign: 'center',
      },
    },
    MuiCard: {
      root: {
        overflow: 'visible',
      },
    },
    // MuiToolbar: {
    //   root: {
    //     minHeight: 48,
    //     height: 48,
    //   },
    // },
  },
});

export default responsiveFontSizes(theme);
