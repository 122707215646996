import { List, Datagrid, TextField } from 'react-admin';

import { __ } from '@adac/core-model';

import UserFilter from './UserFilter';
import { GenericListActions } from '../common/GenericListActions';
import { EditButtonWithRole } from '../common/react-admin/EditButtonWithRole';
import { CustomPagination } from '../cases/CustomPagination';

export default (props: any) => (
  // bulkActionButtons contains stuff like the dlete checkbox
  // actions contains the export csv button and functionality
  <List
    {...props}
    bulkActionButtons={false}
    actions={<GenericListActions allowCreate />}
    filters={<UserFilter />}
    pagination={<CustomPagination {...({} as any)} />}
  >
    <Datagrid>
      <TextField source='id' label={__('User id')} /* sortable={false} */ />
      <TextField source='name' label={__('Name')} /* sortable={false} */ />
      <TextField
        source='username'
        label={__('Username')} /* sortable={false} */
      />
      <TextField source='roleName' label={__('Role')} sortable={false} />
      <TextField
        source='dispatchTarget'
        label={__('DispatchTarget')}
        sortable={false}
      />
      <TextField source='lastLogin' label={__('lastLogin')} sortable />
      <TextField source='passwordUpdatedAt' label={__('passwordUpdatedAt')} />
      <EditButtonWithRole label={__('Edit')} />
    </Datagrid>
  </List>
);
