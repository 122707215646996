import {
  MenuItem,
  FormControl,
  Input,
  Select,
  Chip,
  InputLabel,
} from '@material-ui/core';

import { __ } from '@adac/core-model';

import { Field, FieldProps, useFormikContext } from 'formik';
import { VariantWorkaround } from '@adac/core-view';
import { useRegionList } from '../../dataProvider/useRegionList';
import { HolidayFormValues } from './HolidayFormValues';

export const SimpleRegionSelector = () => {
  const { setFieldValue, values } = useFormikContext<HolidayFormValues>();
  const { regions } = useRegionList();

  return (
    <Field
      name='regionNames'
      render={({ field }: FieldProps<'regionNames', HolidayFormValues>) => (
        <VariantWorkaround variant='outlined'>
          <Select
            label={__('Related region')}
            // variant="outlined"
            {...field}
            onChange={(e) => setFieldValue('regionNames', [e.target.value])}
          >
            {regions &&
              regions.map((region) => (
                <MenuItem
                  key={region.name}
                  selected={region.name === values?.regionNames?.[0]}
                  value={region.name}
                >
                  {region.name}
                </MenuItem>
              ))}
          </Select>
        </VariantWorkaround>
      )}
    />
  );
};

// const useStyles = makeStyles((theme: Theme) => createStyles({
//   formControl: {
//     margin: theme.spacing(1),
//     minWidth: 120,
//     maxWidth: 300,
//   },
//   chips: {
//     display: 'flex',
//     flexWrap: 'wrap',
//   },
//   chip: {
//     margin: 2,
//   },
//   noLabel: {
//     marginTop: theme.spacing(3),
//   },
// }));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export const MultipleRegionSelector = (): JSX.Element => {
  const { regions } = useRegionList();

  const getNameForRegionId = (name: string) => {
    const region = regions.find((r) => r.name?.toString() === name.toString());
    if (region) {
      return region?.name;
    }
    return null;
  };

  return (
    <Field
      name='regionNames'
      render={({ field }: FieldProps<'regionNames', HolidayFormValues>) => (
        <FormControl>
          <InputLabel>{__('Related region')}</InputLabel>
          <Select
            id='regionNames'
            multiple
            title={__('Related region')}
            input={<Input />}
            renderValue={(selected) => (
              <div>
                {(selected as string[])?.map((value) => (
                  <Chip key={value} label={getNameForRegionId(value)} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
            {...field}
            value={field.value || []}
          >
            {regions &&
              regions.map((region) => (
                <MenuItem key={region.name} value={region.name}>
                  {region.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    />
  );
};
