export const defaultLocationCoords = {
    latitude: 48.13352,
    longitude: 11.52831,
};
export const defaultLocationCoordsOnError = {
    latitude: 0,
    longitude: 0,
};
export const defaultLocationDisplayOnError = {
    latitude: 53.681265,
    longitude: 8.362691,
};
export function isLocationCoordsValid(coords) {
    return (coords &&
        typeof coords.latitude === 'number' &&
        typeof coords.latitude === 'number' &&
        !(coords.latitude === defaultLocationCoordsOnError.latitude &&
            coords.longitude === defaultLocationCoordsOnError.longitude) &&
        !(coords.latitude === defaultLocationDisplayOnError.latitude &&
            coords.longitude === defaultLocationDisplayOnError.longitude));
}
export function getCustomerCoordsType(coords) {
    if ((coords === null || coords === void 0 ? void 0 : coords.longitude) === undefined || (coords === null || coords === void 0 ? void 0 : coords.longitude) === null)
        return 'init';
    return isLocationCoordsValid(coords) ? 'home' : 'error';
}
export function extractLocationCoords(object) {
    return {
        latitude: Number(object === null || object === void 0 ? void 0 : object.latitude) || defaultLocationCoordsOnError.latitude,
        longitude: Number(object === null || object === void 0 ? void 0 : object.longitude) || defaultLocationCoordsOnError.longitude,
    };
}
export function extractLocationCoordsWithConfidence(object) {
    const coords = extractLocationCoords(object);
    return Object.assign(Object.assign({}, coords), { confidence: coords.latitude ? 1 : 0 });
}
