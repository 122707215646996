import moment from 'moment';
import defaultI18n from '../helpers/i18n.js';
moment.locale('de');
function isMoment(d) {
    return !!(d && d.format !== undefined);
}
export function isDate(d) {
    return !!(d && d.toISOString !== undefined);
}
function makeMoment(m) {
    return isMoment(m) ? m : moment && moment(m);
}
export const dateFormat = process.env.REACT_APP_SIMPLE_DATE_FORMAT || 'D/M/YYYY';
export const dateEditFormat = dateFormat
    .replace(/(\W?)D+(\W?)/, '$1DD$2')
    .replace(/(\W?)M+(\W?)/, '$1MM$2')
    .replace(/(\W?)Y+(\W?)/, '$1YYYY$2');
const weekdays = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
];
export const translateWeekdaysToGermanWorkaround = (dateString) => weekdays.reduce((finalString, day) => finalString.replace(day, defaultI18n(`${day}`)), dateString);
export const date = (m, format = dateFormat) => {
    const mObj = makeMoment(m);
    if (isMoment(mObj))
        return translateWeekdaysToGermanWorkaround(mObj.locale('de').format(format));
    return isDate(mObj) ? mObj === null || mObj === void 0 ? void 0 : mObj.toISOString().substring(0, 10) : mObj;
};
export const iso = (m) => {
    const mObj = makeMoment(m);
    if (isMoment(mObj))
        return mObj.toISOString();
    return isDate(mObj) ? mObj === null || mObj === void 0 ? void 0 : mObj.toISOString() : `${mObj}`;
};
export const timestampFormat = process.env.REACT_APP_SIMPLE_TIMESTAMP_FORMAT || 'D/M/YY HH:mm';
export const timestampEditFormat = timestampFormat
    .replace(/(\W?)D+(\W?)/, '$1DD$2')
    .replace(/(\W?)M+(\W?)/, '$1MM$2')
    .replace(/(\W?)Y+(\W?)/, '$1YYYY$2');
export const time = (m) => {
    const mObj = makeMoment(m);
    if (isMoment(mObj))
        return mObj.format(timestampFormat);
    return isDate(mObj) ? mObj === null || mObj === void 0 ? void 0 : mObj.toISOString() : mObj;
};
export const yearMonth = (m) => {
    const mObj = makeMoment(m);
    if (isMoment(mObj))
        return mObj.format('YYYY/MM');
    return isDate(mObj)
        ? mObj === null || mObj === void 0 ? void 0 : mObj.toISOString().substring(0, 7).replace(/-\/\\/, '/')
        : mObj;
};
