import { __, defaultNullableI18n } from '../index.js';
class HTTPError extends Error {
    constructor(message, name = 'Error', stack, status, additionalData) {
        super(message);
        this.message = message;
        this.name = name;
        this.stack = stack;
        this.status = status;
        this.additionalData = additionalData;
        this.toString = () => `${this.name}: ${this.message}`;
        this.toLocalizedString = () => defaultNullableI18n(this.message) ||
            __('Server responded, but an error occured');
        this.type = name;
    }
}
export default HTTPError;
